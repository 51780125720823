import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {  Search } from 'react-bootstrap-table2-toolkit';

import {
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    Row,
} from 'reactstrap';
import PageTitle from '../components/PageTitle';
import axios, { ASSETS_URL } from '../axios';
import UserImg from '../assets/images/topx-user.jpg';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input
            type="select"
            name="select"
            id="no-entries"
            className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>;
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);




// Our Custom Function
const handleNextPage = (pageProp, myPage) => {

    let newPage = parseInt(myPage);
    pageProp.onPageChange(newPage ? newPage : 1);
}
const Table = ({ applied, orders, setUserModal, setAlert, setRefresh, pros, refresh }) => {

    const [hideRow, setHide] = useState([])
    const [size, setSize] = useState(10);
  


    const option = {
        custom: true,
        alwaysShowAllBtns: true,
        sizePerPage: size,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ],

    }
    const imageFormatter = (cell) => {
        if (!cell) {
            return <img src={UserImg} className="avatar-xs rounded" alt="user avatar" />;
        }
        return <img src={ASSETS_URL + cell} className="avatar-xs rounded" alt="user avatar" />;
    };



    const [selectedData, setSelected] = useState();

    // setTimeout(()=>{setPage(1)},1000);


    function serviceFormatter(cell, row) {
        return (
            <span dangerouslySetInnerHTML={{ __html: cell }}></span>

        );
    }
    function orderFormatter(cell, row) {
        return (
            <a href ={`/orders/${row.id}`}>{cell}</a>

        );
    }
    function proformatter(cell, row) {

        return (

            <span className="as" dangerouslySetInnerHTML={{ __html: cell }}>

            </span>
        );
    }
    function Resproformatter(cell, row) {

        return (

            <span className="as" dangerouslySetInnerHTML={{ __html: cell }}>

            </span>
        );
    }

    const columns = [
        {
            dataField: 'id',
            text: 'OrderID',
            sort: true,
            formatter: orderFormatter,
        },
        {
            dataField: 'orderService',
            text: 'Service Name/ Order',
            sort: true,
            formatter: serviceFormatter,
        },
        {
            dataField: 'createdAt',
            text: 'Date',
            sort: true,
        },


        {
            dataField: 'appliedPros',
            text: 'Contacted Pros',
            sort: true,
            formatter: proformatter
        },
        {
            dataField: 'clientContact',
            text: 'Responded to',
            sort: true,
            formatter: Resproformatter
        },
        {
            dataField: 'coins',
            text: 'Paid',
            sort: true,
        },

    ];

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'asc',
        },
    ];
    const { SearchBar } = Search;

    const options = [{
        label: "All Accounts",
        value: "All Accounts",
    }, {
        label: "Confirmed Accounts",
        value: "Cofirmed Accounts"
    }]
    useEffect(() => {
        document.getElementById("no-entries")?.addEventListener("change", function () {
            setSize(document.getElementById("no-entries").value);
        });

        return () => {
            document.getElementById("no-entries")?.removeEventListener("change", function () {
                setSize(document.getElementById("no-entries").value);
            });

        }
    })


    return (
        <>
            {!orders ? <div style={{ position: "absolute", left: "56%", top: "50%", transform: "translate(-50%, -50%)" }}><lottie-player src="loader.json" background="transparent" speed="1" style={{ width: "150px", height: "150px" }} loop autoplay></lottie-player>
                <p style={{ textAlign: "center", marginTop: "1rem", fontWeight: "bold", color: "purple" }}>Please Wait ...</p> </div>

                :
                <Card>
                    <CardBody>
                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={orders}
                            columns={columns}
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: 'orders.csv' }}>
                            {(props) => (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <Row className="align-items-center pl-2">
                                                <p className="m-0 mr-2">Search: </p>
                                                <SearchBar {...props.searchProps} />
                                            </Row>
                                        </Col>

                                    </Row>
                                    <PaginationProvider pagination={paginationFactory(option)}>
                                        {
                                            ({
                                                paginationProps,
                                                paginationTableProps
                                            }) => (<>
                                                <BootstrapTable
                                                    keyField="id"
                                                    hiddenRows={hideRow}
                                                    data={orders}
                                                    {...props.baseProps}
                                                    bordered={true}
                                                    hover
                                                    wrapperClasses="table-responsive"

                                                    defaultSorted={defaultSorted}
                                                    columns={columns}
                                                    {...paginationTableProps}
                                                />

                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                                <div id="jump" style={{ display: "flex" }}>
                                                    <span style={{ marginTop: "0.6rem" }}>Jump to Page : </span>
                                                    <Input style={{ width: "12%", marginLeft: "1rem" }} type="text" onChange={(e) => { handleNextPage(paginationProps, e.target.value) }} />
                                                </div>
                                                <PaginationListStandalone
                                                    style={{ float: "right" }}
                                                    {...paginationProps}
                                                />
                                            </>
                                            )
                                        }
                                    </PaginationProvider>
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
            }
        </>
    );
};


const DetailUserPage = () => {
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState(null);
    const [pro, setPro] = useState();
    const [orders, setOrders] = useState();
    const [services, setServices] = useState([]);
    const [applied, setApplied] = useState()
    const params = useParams();
    useEffect(() => {
        const fetchUsers = async () => {
            const usersdata = await axios.get('/api/v1/admin/users/getAll');
            const { data } = await axios.get(`/api/v1/admin/users/get/${params.id}`);
            if (params.proId !== "null") {
                const pro = await axios.get(`/api/v2/admin/pro/${params.proId}`);
                setPro(pro.data)
            }
            setUsers(usersdata.data)
            if (data) {
                setUser(data);
            }
        };

        fetchUsers();
        let arr = []
        let proOrderarr = []
        let appliedarr = []
        const fetchOrders = async () => {
            const { data } = await axios.get('/api/v1/admin/order');
            await axios.get(`/api/v2/admin/pro`).then(res => {

                appliedarr = res.data
            })
            await axios.get('/api/v1/admin/order/orderPro').then(res => {
                proOrderarr = res.data;
            });
            let viewedPro = []
            await axios.get(`api/v1/admin/order/chats?id=${params.id}`).then(res => {
                viewedPro = res.data
            })
            let chats= []
             await axios.get(`api/v1/admin/order/chats-msg`).then(res => {
              chats = res.data
            })

            const ordersArr = data && data.filter((e) => e.userId === params.id).map((order) => {
                let arr = []
                let viewArr = []
                viewArr.push(viewedPro.filter((s) => s.offer_viewed !== null && s.orderId === order.id).map((q) => q.proId)[0])
                proOrderarr.filter(function (e) { return e.orderId === parseInt(order.id); }).forEach((e) =>
                    arr.push(e.proId)
                )
                // let proName = appliedarr.filter((s) => s.id === order.proId).map((e) => e.name)[0] ? order.proId === null ? "N/A" : `<a href= "/pros/${order.proId}">${appliedarr.filter((s) => s.id === order.proId).map((e) => e.name)[0] ? appliedarr.filter((s) => s.id === order.proId).map((e) => e.name)[0] : "N/A"}</a>` : "N/A"
                let newarr;
                let newarrs;
                let conArr=[]
viewedPro.map((e)=>{
    console.log(e.id)
   conArr.push ( {name: chats.filter((f)=>e.id === f.chatId).map((g)=>g?.pro?.name)[0],
  id: chats.filter((f)=>e.id === f.chatId).map((g)=>g?.pro?.id)[0]})
})
arr && arr.length > 0 && arr.map((f) => {

    newarrs = `${newarrs === undefined ? "<span style='display: none'></span>" : newarrs==="undefined"?"<span style='display: none'></span>" :newarrs }`  + `${conArr.filter((e) => e.id === f).map((i) => i.name)[0]===undefined?"<span style='display: none'></span>":`<a href= ${`/pros/${f}`}>${conArr.filter((e) => e.id === f).map((i) => i.name)[0]===undefined?"<span style='display: none'></span>":conArr.filter((e) => e.id === f).map((i) => i.name)[0]}</a><br>`}`

})
                arr && arr.length > 0 && arr.map((f) => {
                    viewArr.map((d) => {


                        newarr = `${newarr === undefined ? "<span style='display: none'></span>" : newarr==="undefined"?"<span style='display: none'></span>" :newarr?newarr:"<span style='display: none'></span>"}` + `${f === d ? "✔️📥 " : ""}` + `${appliedarr.filter((e) => e.id === f).map((i) => i.name)[0]===undefined?"<span style='display: none'></span>":`<a href= ${`/pros/${f}`}>${appliedarr.filter((e) => e.id === f).map((i) => i.name)[0]===undefined?"<span style='display: none'></span>":appliedarr.filter((e) => e.id === f).map((i) => i.name)[0]}</a><br>`}`


                    })

                })

                return {
                    id: order.id,
                    clientName: order?.user?.lastName
                        ? order?.user?.firstName + ' ' + order?.user?.lastName
                        : order?.user?.firstName,
                    clientContact: newarrs,
                    orderService: order && order.service && `<a href="/services/${order.service.id}">${order.service.titleEN}</a>`,
                    createdAt: order?.createdAt,
                    serviceDetails: order && order.service && order.service,
                    //appliedPros: order.pros.length,
                    coins: order && `${order.coins} coins`,
                    info: order && order.info,
                    createdAt: (new Date(order?.createdAt).getDate() < 10 ? '0' : '') + new Date(order?.createdAt).getDate() + "." + (new Date(order?.createdAt).getMonth() < 10 ? '0' : '') + new Date(order?.createdAt).getMonth() + "." + new Date(order?.createdAt).getFullYear() + "- " + new Date(order?.createdAt).getHours() + ":" + (new Date(order?.createdAt).getMinutes() < 10 ? '0' : '') + new Date(order?.createdAt).getMinutes(),
                    expiry_date: order && order.expiry_date,
                    appliedPros: newarr
                };
            });

            setOrders(ordersArr);

            const services = await axios.get('/api/v1/admin/service');
            const options = services.data.map((service) => {
                return {
                    value: service.id,
                    label: service.titleEN,
                };
            });
            setServices(options);
        };

        fetchOrders();
    }, []);




    return (

        <div>
            {!user ? <div style={{ position: "absolute", left: "56%", top: "50%", transform: "translate(-50%, -50%)" }}><lottie-player src="loader.json" background="transparent" speed="1" style={{ width: "150px", height: "150px" }} loop autoplay></lottie-player>
                <p style={{ textAlign: "center", marginTop: "1rem", fontWeight: "bold", color: "purple" }}>Please Wait ...</p> </div> :
                <>
                    <Row className="page-title">
                        <Col className="col-12">
                            <PageTitle
                                breadCrumbItems={[
                                    { label: 'Users', path: '/users' },
                                    { label: 'User Details', path: '/users/:id', active: true },
                                ]}
                                title={'User Details'}
                            />
                        </Col>
                    </Row>
                    <Card>
                        {user && (
                            <>

                                <Form>
                                    <CardBody className="p-4">
                                        <Row noGutters>
                                            <Col md={4} className="pr-2 pl-2">
                                                <h6>Avatar</h6>

                                                <div className="profile_image_preview" style={{ buser: "1px solid #e2e7f1", width: "120px", height: "120px" }}>
                                                    <img
                                                        src={user && user?.imageUrl ? ASSETS_URL + user?.imageUrl : UserImg}
                                                        style={{ objectFit: 'cover', width: 120, height: 120 }}
                                                        alt="profile"
                                                    />

                                                </div>

                                            </Col>
                                            <Col md={8} className="pl-4 pr-2">
                                                <Row noGutters>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="name"> Full Name</Label>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            placeholder="Name"
                                                            value={`${user.firstName} ${user.lastName === "null" ? '' : user.lastName === null ? "" : user.lastName}`}
                                                            required
                                                            readOnly
                                                        />
                                                    </Col>

                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="phone">Mobile Phone</Label>

                                                        <Input
                                                            name="Mobile_phone"
                                                            id="phone"
                                                            placeholder="Phone"
                                                            value={user?.phone ? user?.phone : 'N/A'}
                                                            readOnly
                                                            required

                                                        />

                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="email">Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={user?.email ? user.email : 'N/A'}
                                                            readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="website">Registered Date</Label>
                                                        <Input
                                                            type="url"
                                                            name="overview_website"
                                                            id="website"
                                                            placeholder="Date"
                                                            value={(new Date(user?.createdAt).getDate() < 10 ? '0' : '') + new Date(user?.createdAt).getDate() + "." + (new Date(user?.createdAt).getMonth() < 10 ? '0' : '') + new Date(user?.createdAt).getMonth() + "." + new Date(user?.createdAt).getFullYear() + "- " + new Date(user?.createdAt).getHours() + ":" + (new Date(user?.createdAt).getMinutes() < 10 ? '0' : '') + new Date(user?.createdAt).getMinutes()}

                                                        />
                                                    </Col>
                                                    <Col md={12} className="pr-4 mb-4">
                                                        <Label for="phone">Business Account</Label>
                                                        {user.isPro && pro ? <a href={`/pros/${pro?.id}`}>
                                                            <Input
                                                                style={{ cursor: "pointer", color: "blue" }}
                                                                value={user.isPro && pro && pro?.name ? pro.name : "N/A"}
                                                                required
                                                            /></a> : <Input
                                                            value={"N/A"}
                                                            required
                                                        />}

                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Form>
                            </>
                        )}
                    </Card>
                    <Card>
                        <CardBody>
                            <Table applied={applied} orders={orders} />
                        </CardBody>
                    </Card>
                </>
            }
        </div>

    );
};

export default DetailUserPage;
