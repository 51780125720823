import React, { CSSProperties, useEffect, useState } from 'react';
import { Layers, Plus, Trash2 } from 'react-feather';
import Select from 'react-select';
import {
    Button,
    ButtonGroup,
    Col,
    CustomInput,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import axios, { ASSETS_URL } from '../../axios';
import { Question, SimpleOption } from '../../types';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RootState } from '../../redux/store';
import {
    initChoices as initChoicesAction,
    setClientChoiceType as setClientChoiceTypeAction,
    setModalStateQuestion as setModalStateQuestionAction,
    setProChoiceType as setProChoiceTypeAction,
    addChoice as addChoiceAction,
    removeChoice as removeChoiceAction,
    removeProChoice as removeProChoiceAction,
    duplicateChoice as duplicateChoiceAction,
    addProChoice as addProChoiceAction,
    resetChoices as resetChoicesAction,
    handleChoiceChange as handleChoiceChangeAction,
    resetDeletedChoices as resetDeletedChoicesAction,
    addTemplateChoices as addTemplateChoicesAction,
    reorderChoices as reorderChoicesAction,
} from './actions';
import { Dispatch } from 'redux';
import { StateProps, DispatchProps, OwnProps, ModalStateQuestion, ChoiceType, Action, ChangeKeys, Choice, StateChoice } from './types';
import { ItemInterface, ReactSortable, Sortable } from 'react-sortablejs';

type EditQuestionModalProps = StateProps & DispatchProps & OwnProps;

const deleteButtonWrapperCss: CSSProperties = { position: 'absolute', top: '0.5rem', left: '50%', transform: 'translateX(-50%)' };

type TempChoice = StateChoice & ItemInterface;

const EditQuestionModal = (props: EditQuestionModalProps) => {
    const {
        activeService,
        questionEditModal,
        setQuestionEditModal,
        questions,
        setQuestions,
        setAlert,
        templateQuestions,
        handleRemoveQuestion,
        setClientChoiceType,
        setProChoiceType,
        clientChoiceType,
        proChoiceType,
        question,
        setModalStateQuestion,
        initChoices,
        addChoice,
        removeChoice,
        duplicateChoice,
        addProChoice,
        resetChoices,
        handleChoiceChange,
        removeProChoice,
        choices,
        deletedChoices,
        resetDeletedChoices,
        addTemplateChoices,
        reorderChoices,
    } = props;

    const [deleteStarted, setDeleteStarted] = useState(false);
    const [tempChoices, setTempChoices] = useState<TempChoice[]>([]);

    useEffect(() => {
       if(choices.length !== 0){
           setTempChoices(choices);
       }
    }, [choices])

    const reorderLocal = (reorderedChoices: TempChoice[], sortable: Sortable) => {


        
        if(sortable && reorderedChoices.length > 0){
            try {
                setTempChoices(reorderedChoices);
            } catch (error) {
                console.log('reorderLocal', error)
            }
        }
    }

    const handleChoiceReorder = (evt: Sortable.SortableEvent, sortable: any, store: any) => {
        const choicesToSet = tempChoices.map(({ selected, chosen, filtered, ...choice }) => choice)
        reorderChoices(choicesToSet);
    }

    const [templateChoices, setTemplateChoices] = useState<SimpleOption[] | null>(null);

    useEffect(() => {
        if (questionEditModal) {
            const questionClient = questionEditModal.questionText.filter((q) => q.belongs === 'client')[0];
            const questionPro = questionEditModal.questionText.filter((q) => q.belongs === 'pro')[0];

            const description = questionEditModal.descriptionText[0];

            setClientChoiceType(questionClient.multiple ? 'multiple' : 'single');
            setProChoiceType(questionPro.multiple ? 'multiple' : 'single');

            setModalStateQuestion({
                filter: questionEditModal.filter_show,
                proPage: questionEditModal.pro_page,
                skip: questionEditModal.skip,
                clientOnly: questionEditModal.client_only,
                inputBar: questionEditModal.input_bar,
                showImages: questionClient.show_images,
                clientTitleId: questionClient.id,
                clientTitleEnglish: questionClient.eng,
                clientTitleRussian: questionClient.ru,
                clientTitleUzbek: questionClient.uz,
                proTitleId: questionPro.id,
                proTitleEnglish: questionPro.eng,
                proTitleRussian: questionPro.ru,
                proTitleUzbek: questionPro.uz,
                descriptionId: description ? description.id : null,
                descriptionEnglish: description ? description.eng : '',
                descriptionRussian: description ? description.ru : '',
                descriptionUzbek: description ? description.uz : '',
            });

            initChoices(questionEditModal);
        }
    }, [questionEditModal, setProChoiceType, setClientChoiceType, setModalStateQuestion, initChoices]);

    useEffect(() => {
        if (templateQuestions) {
            const options = templateQuestions.map((temp) => {
                return {
                    value: temp.id,
                    label: temp.questionText.filter((q) => q.belongs === 'client')[0].eng,
                };
            });

            setTemplateChoices(options);
        }
    }, [templateQuestions]);

    if(!questionEditModal){
        return null;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number, pro: boolean) => {
        const name = e.target.name as ChangeKeys;
        const value = e.target.value;

        handleChoiceChange(name, value, i, pro)
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof Question;
        setModalStateQuestion({
            ...question,
            [name]: e.target.value,
            titlesEdited: true,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const sanitizedChoises: Choice[] = choices.map(({ id, idCopy, ...rest }) => ({ id: idCopy, ...rest}))
        const body = {
            questionId: questionEditModal.id,
            deletedchoices: deletedChoices,
            options: {
                skip: question.skip,
                filter: question.filter,
                proPage: question.proPage,
                clientOnly: question.clientOnly,
                inputBar: question.inputBar,
                showImages: question.showImages,
            },
            title: {
                clientEnglish: question.clientTitleEnglish,
                clientRussian: question.clientTitleRussian,
                clientUzbek: question.clientTitleUzbek,
                proEnglish: question.proTitleEnglish,
                proRussian: question.proTitleRussian,
                proUzbek: question.proTitleUzbek,
                titlesEdited: question.titlesEdited,
                clientTitleId: question.clientTitleId,
                proTitleId: question.proTitleId,
            },
            description: {
                id: question.descriptionId,
                eng: question.descriptionEnglish,
                ru: question.descriptionRussian,
                uz: question.descriptionUzbek,
            },
            clientChoiceType,
            proChoiceType,
            choices: sanitizedChoises,
            serviceId: activeService,
        };

        const res = await axios.put('/api/v2/admin/questions', body);

        if (res.status === 200) {
            const questionInState = questions.findIndex((q) => q.id === questionEditModal.id);

            if (questionInState > -1) {
                const title = questions[questionInState].questionText.filter((q) => q.belongs === 'client')[0];
                title.eng = question.clientTitleEnglish;
                setQuestions([...questions]);
            }

            toggle();

            setAlert({
                color: 'success',
                message: 'question successfully updated',
            });
        }
    };

    const toggle = () => {
        setQuestionEditModal(null);
        const newQuestion: ModalStateQuestion = {
            filter: false,
            proPage: false,
            skip: false,
            clientOnly: false,
            inputBar: false,
            clientTitleEnglish: '',
            clientTitleRussian: '',
            clientTitleUzbek: '',
            proTitleEnglish: '',
            proTitleRussian: '',
            proTitleUzbek: '',
            descriptionEnglish: '',
            descriptionRussian: '',
            descriptionUzbek: '',
        }
        setModalStateQuestion(newQuestion);
        setClientChoiceType('single');
        setProChoiceType('single');
        resetChoices();
        resetDeletedChoices();
    };

    const handleUseTemplate = (state: SimpleOption | null) => {
        const template = templateQuestions.find((t) => t.id === (state && state.value));

        if (template) {
            const questionClient = template.questionText.filter((q) => q.belongs === 'client')[0];
            const questionPro = template.questionText.filter((q) => q.belongs === 'pro')[0];

            setClientChoiceType(questionClient.multiple ? 'multiple' : 'single');
            setProChoiceType(questionPro.multiple ? 'multiple' : 'single');

            setModalStateQuestion({
                filter: template.filter_show,
                proPage: template.pro_page,
                skip: template.skip,
                clientOnly: template.client_only,
                inputBar: template.input_bar,
                clientTitleId: questionClient.id,
                clientTitleEnglish: questionClient.eng,
                clientTitleRussian: questionClient.ru,
                clientTitleUzbek: questionClient.uz,
                proTitleId: questionPro.id,
                proTitleEnglish: questionPro.eng,
                proTitleRussian: questionPro.ru,
                proTitleUzbek: questionPro.uz,
            });
            addTemplateChoices(template);
        }
    };

    const uploadChoiceImage = async (e: any, i: number) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            handleChoiceChange('choiceImage', data.image, i, false)
        }
    };

    const handleDeleteQuestion = async () => {
        setDeleteStarted(true);
        await handleRemoveQuestion(questionEditModal)
        setDeleteStarted(false);
    };
    return (
        <Modal isOpen={!!questionEditModal} size="xl">
            <ModalHeader toggle={toggle}>
                    Edit Question
                    <div style={deleteButtonWrapperCss}>
                        <Button
                            onClick={handleDeleteQuestion}
                            disabled={deleteStarted}
                            type="button"
                            color='danger'
                        >
                            <Trash2 />
                        </Button>
                    </div>
            </ModalHeader>
            <Form autoComplete='none' onSubmit={handleSubmit}>
                <ModalBody className="p-4">
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h6>Template</h6>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={handleUseTemplate}
                                options={templateChoices ? templateChoices : []}></Select>
                        </Col>
                        <Col md={6} className="justify-content-end">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Display at:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            onChange={(state) =>
                                                setModalStateQuestion({
                                                    ...question,
                                                    filter: state.target.checked,
                                                })
                                            }
                                            checked={question.filter}
                                            id="filter"
                                            type="checkbox"
                                            label="Filter"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            onChange={(state) =>
                                                setModalStateQuestion({
                                                    ...question,
                                                    proPage: state.target.checked,
                                                })
                                            }
                                            checked={question.proPage}
                                            id="proPage"
                                            type="checkbox"
                                            label="Pro Page"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Other:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            onChange={(state) =>
                                                setModalStateQuestion({
                                                    ...question,
                                                    skip: state.target.checked,
                                                })
                                            }
                                            checked={question.skip}
                                            id="skip"
                                            type="checkbox"
                                            label="Skip"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            id="clientOnly"
                                            onChange={(state) =>
                                                setModalStateQuestion({
                                                    ...question,
                                                    clientOnly: state.target.checked,
                                                })
                                            }
                                            checked={question.clientOnly}
                                            type="checkbox"
                                            label="Client Only"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            onChange={(state) =>
                                                setModalStateQuestion({
                                                    ...question,
                                                    inputBar: state.target.checked,
                                                })
                                            }
                                            checked={question.inputBar}
                                            id="inputBar"
                                            type="checkbox"
                                            label="Input bar"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <h4 className="mt-4 text-center">Question</h4> */}
                    <hr className="mt-4" />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Question
                            </span>
                        </h5>
                    </div>
                    <Row className="mt-3 p-2" style={{ backgroundColor: '#F9F9FA' }}>
                        <Col md={6}>
                            <h6>Client Question</h6>
                            <Input
                                name="clientTitleEnglish"
                                onChange={handleTitleChange}
                                defaultValue={question.clientTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleRussian"
                                onChange={handleTitleChange}
                                defaultValue={question.clientTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleUzbek"
                                onChange={handleTitleChange}
                                defaultValue={question.clientTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <h6>Pro Question</h6>
                            <Input
                                name="proTitleEnglish"
                                onChange={handleTitleChange}
                                defaultValue={question.proTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required={!question.clientOnly}
                            />
                            <Input
                                name="proTitleRussian"
                                onChange={handleTitleChange}
                                defaultValue={question.proTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required={!question.clientOnly}
                            />
                            <Input
                                name="proTitleUzbek"
                                onChange={handleTitleChange}
                                defaultValue={question.proTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required={!question.clientOnly}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Description
                            </span>
                        </h5>
                    </div>
                    <Row className="mt-3 p-2">
                        <Col md={6}>
                            <h6>Description</h6>
                            <Input
                                name="descriptionEnglish"
                                defaultValue={question.descriptionEnglish}
                                onChange={handleTitleChange}
                                placeholder="English"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionRussian"
                                defaultValue={question.descriptionRussian}
                                onChange={handleTitleChange}
                                placeholder="Russian"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionUzbek"
                                defaultValue={question.descriptionUzbek}
                                onChange={handleTitleChange}
                                placeholder="Uzbek"
                                className="mb-3"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Choices
                            </span>
                        </h5>
                    </div>
                    <Row>
                        <div className="uploader-left d-flex align-items-center justify-content-center">
                            <CustomInput
                                onChange={(state) =>
                                    setModalStateQuestion({
                                        ...question,
                                        showImages: state.target.checked,
                                        titlesEdited: true,
                                    })
                                }
                                checked={question.showImages}
                                id="showImages"
                                type="checkbox"
                                label="Show images"
                            />
                        </div>
                        <Col md={5}>
                            <Row className="ml-4 mt-2 mb-1">
                                <h6 className="mr-2">Client Choices</h6>
                                <ButtonGroup size="sm" className="flex width-lg">
                                    <Button
                                        type="button"
                                        onClick={() => setClientChoiceType('single')}
                                        outline={clientChoiceType !== 'single'}
                                        color="primary"
                                    >
                                        Single
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setClientChoiceType('multiple')}
                                        outline={clientChoiceType !== 'multiple'}
                                        color="primary">
                                        Multiple
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <Row className="ml-4 mt-2 mb-1">
                                <h6 className="mr-2">Pro Choices</h6>
                                <ButtonGroup size="sm" className="flex width-lg">
                                    <Button
                                        type="button"
                                        onClick={() => setProChoiceType('single')}
                                        outline={proChoiceType !== 'single'}
                                        color="primary">
                                        Single
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setProChoiceType('multiple')}
                                        outline={proChoiceType !== 'multiple'}
                                        color="primary">
                                        Multiple
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                    <ReactSortable<StateChoice>
                        list={tempChoices}
                        onEnd={handleChoiceReorder}
                        setList={reorderLocal}
                    >
                        {tempChoices.map((choice, i) => {
                            const handleChoiceRemove = () => {
                                removeChoice(i)
                            };
                            return (
                                <Row key={choice.id} className="mt-3 p-2 align-items-start" style={{ backgroundColor: '#F9F9FA' }}>
                                    <div className="uploader-left">
                                        <div className="uploader-left">
                                            <div className="uploader-outer">
                                                <label htmlFor={'choice-image-' + i} className="uploader">
                                                    {choice.choiceImage ? (
                                                        <img
                                                            alt='choice'
                                                            style={{
                                                                width: 140,
                                                                height: 140,
                                                                borderRadius: 4,
                                                                objectFit: 'cover',
                                                            }}
                                                            src={ASSETS_URL + choice.choiceImage}
                                                        />
                                                    ) : (
                                                        <div className="inside">
                                                            <Plus />
                                                            <h4>Upload</h4>
                                                        </div>
                                                    )}
                                                </label>
                                                <input
                                                    type="file"
                                                    id={'choice-image-' + i}
                                                    onChange={(e) => uploadChoiceImage(e, i)}
                                                />
                                            </div>
                                            <Row noGutters>
                                                <Col md={6}>
                                                    <Button
                                                        type="button"
                                                        className="d-relative question-index w-100 h-100 mr-1"
                                                        color="soft-primary">
                                                        {i + 1}
                                                        <div onClick={handleChoiceRemove} className="remove-choice">
                                                            -
                                                        </div>
                                                    </Button>
                                                </Col>
                                                {choices.length === i + 1 && (
                                                    <Col md={6}>
                                                        <Button
                                                            type="button"
                                                            onClick={addChoice}
                                                            className="w-100 h-100 ml-1"
                                                            color="primary">
                                                            <Plus />
                                                        </Button>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                    <Col md={5} className="ml-4">
                                        <Input
                                            name="clientEnglish"
                                            placeholder="English"
                                            className="mb-3"
                                            defaultValue={choice.clientEnglish}
                                            onChange={(e) => handleChange(e, i, false)}
                                            required
                                        />
                                        <Input
                                            name="clientRussian"
                                            placeholder="Russian"
                                            className="mb-3"
                                            defaultValue={choice.clientRussian}
                                            onChange={(e) => handleChange(e, i, false)}
                                            required
                                        />
                                        <Input
                                            name="clientUzbek"
                                            placeholder="Uzbek"
                                            className="mb-3"
                                            defaultValue={choice.clientUzbek}
                                            onChange={(e) => handleChange(e, i, false)}
                                            required
                                        />
                                    </Col>
                                    <Col md={5}>
                                        {choice.proChoices ? (
                                            <div className="pro-choices-inputs">
                                                <button onClick={() => removeProChoice(i)} type="button">
                                                    -
                                                </button>
                                                <Input
                                                    name="proEnglish"
                                                    placeholder="English"
                                                    className="mb-3"
                                                    defaultValue={choice.proEnglish || ''}
                                                    onChange={(e) => handleChange(e, i, true)}
                                                    required
                                                />
                                                <Input
                                                    name="proRussian"
                                                    placeholder="Russian"
                                                    className="mb-3"
                                                    defaultValue={choice.proRussian || ''}
                                                    onChange={(e) => handleChange(e, i, true)}
                                                    required
                                                />
                                                <Input
                                                    name="proUzbek"
                                                    placeholder="Uzbek"
                                                    className="mb-3"
                                                    defaultValue={choice.proUzbek || ''}
                                                    onChange={(e) => handleChange(e, i, true)}
                                                    required
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <Button
                                                    type="button"
                                                    className="w-100 mb-2 d-flex align-items-center justify-content-center"
                                                    color="primary"
                                                    onClick={() => duplicateChoice(i)}>
                                                    <Layers size={20} /> <span className="ml-2">Duplicate Choice</span>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    outline
                                                    className="w-100 mt-2 d-flex align-items-center justify-content-center"
                                                    color="primary"
                                                    onClick={() => addProChoice(i)}>
                                                    <Plus size={20} /> <span className="ml-2">Add Pro Choice</span>
                                                </Button>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            );
                        })}
                    </ReactSortable>
                    
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="ml-1" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Update Question
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};


const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, RootState> = (state): StateProps => ({
    clientChoiceType: state.editQuestionModal.clientChoiceType,
    proChoiceType: state.editQuestionModal.proChoiceType,
    question: state.editQuestionModal.question,
    choices: state.editQuestionModal.choices,
    deletedChoices: state.editQuestionModal.deletedChoices,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
    setClientChoiceType: (type: ChoiceType) => dispatch(setClientChoiceTypeAction(type)),
    setProChoiceType: (type: ChoiceType) => dispatch(setProChoiceTypeAction(type)),
    setModalStateQuestion: (question: ModalStateQuestion) => dispatch(setModalStateQuestionAction(question)),
    initChoices: (question: Question) => dispatch(initChoicesAction(question)),
    addChoice: () => dispatch(addChoiceAction()),
    removeChoice: (index: number) => dispatch(removeChoiceAction(index)),
    duplicateChoice: (index: number) => dispatch(duplicateChoiceAction(index)),
    addProChoice: (index: number) => dispatch(addProChoiceAction(index)),
    resetChoices: () => dispatch(resetChoicesAction()),
    handleChoiceChange: (
        updateKey: ChangeKeys, changeValue: string, index: number, pro: boolean
        ) => dispatch(handleChoiceChangeAction(updateKey, changeValue, index, pro)),
    removeProChoice: (index: number) => dispatch(removeProChoiceAction(index)),
    resetDeletedChoices: () => dispatch(resetDeletedChoicesAction()),
    addTemplateChoices: (template: Question) => dispatch(addTemplateChoicesAction(template)),
    reorderChoices: (choices: StateChoice[]) => dispatch(reorderChoicesAction(choices)),
})

export default  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(EditQuestionModal);
