import React, { useEffect, useRef, useState } from 'react';
import { Calendar, Clock, Plus, X } from 'react-feather';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-windowed-select';
import { Button, Card, CardBody, Col, CustomInput, FormGroup, Input, Label, Row, UncontrolledAlert } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { Editor } from "react-draft-wysiwyg";
import {stateToHTML} from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, ContentState } from "draft-js"
import axios, { ASSETS_URL } from '../axios';
import AddCalendarQuestionModal from '../components/AddCalendarQuestionModal';
import AddQuestionModal from '../components/AddQuestionModal';
import { EditQuestionModal } from '../components/EditQuestionModal';
import EditQuestionOptions from '../components/EditQuestionOptions';
import EditTimeQuestionModal from '../components/EditTimeQuestionModal';
import PageTitle from '../components/PageTitle';
import SortIcon from '../assets/drag-n-drop.svg';
import htmlToDraft from 'html-to-draftjs';
import TextArea from 'antd/lib/input/TextArea';
const EditService = () => {
    const [service, setService] = useState(null);
    const [serviceQuestions, setServiceQuestions] = useState(null);
    const [tempQuestions, setTempQuestions] = useState(null); // used for reordering
    const [categories, setCategories] = useState([]);
    const [keywordText, setKeywordText] = useState('');
    const [calendarQuestionModal, setCalendarQuestionModal] = useState(null);
    const [alert, setAlert] = useState(null);
    const [questionOptionsModal, setQuestionOptionsModal] = useState(null);
    const [timeQuestions, setTimeQuestions] = useState(null);
    const [questionEditModal, setQuestionEditModal] = useState(null);
    const [timeQuestionEditModal, setTimeQuestionEditModal] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [templateQuestions, setTemplateQuestions] = useState(null);
    const [addedTemplates, setAddedTemplates] = useState(null);
    const [services, setServices] = useState([]);
    const [serviceToCopyId, setServiceToCopyId] = useState(null);
    const [additionalBudget, setAdditionalBudget] = useState(null);
    const [additionalTime, setAdditionalTime] = useState(null);
    const [refresh, setRefresh] = useState(null)

    const handleQuestionsReorder = (sortRes, sortable) => {
        if (sortable) {
            const reordered = sortRes.map(({ selected, chosen, filtered, order, ...q }, index) => ({ order: index, ...q }))
            setTempQuestions(reordered);
        }
    }

    // eslint-disable-next-line
    const [modalData, setModalData] = useState(null);
    const [sw, setSwitch] = useState(false);
    const [message, setMsg] = useState("");
    const[hiddenText,setHiddenText] = useState();
    const[DescText,setDescText] = useState();
    const[editorState,setEditor] = useState();
    const[hiddenText2,setHiddenText2] = useState();
    const[editorState2,setEditor2] = useState();

    const [sb, setSwitch2] = useState(false);
    const [message2, setMsg2] = useState("");

    useEffect(()=>{
        const fetchService = async () => {
            const { data } = await axios.get('/api/v1/admin/service/' + params.id);

        const html = data?.HiddenHtml;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorstate = EditorState.createWithContent(contentState);
         setEditor(editorstate);
         setDescText(data?.MetaDescription)
        }

        const html2 = data?.HiddenHtmlUz;
        const contentBlocks = htmlToDraft(html2);
        if (contentBlocks) {
          const contentState2 = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
          const editorstate2 = EditorState.createWithContent(contentState2);
         setEditor2(editorstate2);
       
        }
    }
      fetchService();
    },[])


    const params = useParams();
    const router = useHistory();

    const questionsRef = useRef();

    const handleNewSort = async () => {
        const ordered = tempQuestions.map(({ id, order }) => ({
            qId: id,
            order,
        }));

        await axios.post('/api/v1/admin/question/order', {
            order: ordered,
        });
    };

    useEffect(() => {
        const fetchService = async () => {
            const { data } = await axios.get('/api/v1/admin/service/' + params.id);

            if (data.questions) {
                const questionsArr = data.questions.filter((q) => q.type === 'question');

                const timeQuestions = data.questions.filter(
                    (q) => q.type.includes('time') || q.type.includes('calendar')
                );

                const additionalB = data.questions.find((q) => q.type.includes('additional-b'));
                const additionalT = data.questions.find((q) => q.type.includes('additional-t'));

                setServiceQuestions(questionsArr);
                setTimeQuestions(timeQuestions);

                const filteredQArr = questionsArr.filter(question => {
                    const questionTitle = question.questionText.filter(
                        (q) => q.belongs === 'client'
                    );
                    return questionTitle.length > 0;
                })

                const orderedQuestions = filteredQArr.sort((a, b) => {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }

                    return 0;
                });

                setTempQuestions(orderedQuestions);
                setSwitch(data.populars);
                setSwitch2(data.benefits);
                setAdditionalBudget(additionalB);
                setAdditionalTime(additionalT);
            }
            setService(data);

            const categories = await axios.get('/api/v1/admin/category');
            const categoriesArr = categories.data.map((category) => {
                return {
                    value: category.id,
                    label: category.titleEN,
                };
            });

            setCategories(categoriesArr);

            const templates = await axios.get('/api/v1/admin/question/templates/' + params.id);
            const generalQuestions = templates.data.templates.filter((template) => template.general);

            setTemplates(generalQuestions);
            setTemplateQuestions(templates.data.templates);
            setAddedTemplates(templates.data.addedTemplates);

            const servicesList = await axios.get('/api/v1/admin/service');

            if (servicesList.data) {
                const servicesArr = servicesList.data.map((s) => {
                    return {
                        value: s.id,
                        label: s.titleEN,
                    };

                });

                setServices(servicesArr);
            }
        };

        fetchService();
    }, [refresh, params.id, calendarQuestionModal, questionOptionsModal, questionEditModal, timeQuestionEditModal]);

    const handleAddKeyword = async () => {
        if (keywordText.length > 0) {
            const body = {
                serviceId: params.id,
                tag: keywordText,
            };

            const { data } = await axios.post('/api/v1/admin/service/keyword', body);

            service.keywords = data;
            setKeywordText('');
        }
    };

    const handleRemovingKeyword = async (keyword) => {
        await axios.post('/api/v1/admin/service/keyword-delete', {
            serviceId: params.id,
            tag: keyword,
        });

        const filteredKeywords = service.keywords.filter((key) => key !== keyword);

        setService({
            ...service,
            keywords: filteredKeywords,
        });
    };

    const toggle = () => {
        setModalData(null);
    };

    const handleSaveChanges = async () => {
        // /api/v1/admin/service
        const body = {
            categoryId: service.categoryId,
            hiddenText: hiddenText,
            hiddenText2: hiddenText2,
            DescText: DescText,
            AvgCost: service.AvgCost,
            serviceId: parseInt(params.id),
            title: {
                eng: service.titleEN,
                ru: service.titleRU,
                uz: service.titleUZ,
            },
            image: service.imageUrl,
            order: service.order,
        };

        await axios.put('/api/v1/admin/service', body);

        router.go(0);
    };

    const handleOptionsModal = (question) => {
        setQuestionOptionsModal(question);
    };

    const handlesetQuestionEditModal = async (question) => {
        if (question.type.includes('calendar') || question.type.includes('time')) {
            setQuestionOptionsModal(null);
            return setTimeQuestionEditModal(question);
        }

        const { data: qData } = await axios.get(`/api/v2/admin/questions/${question.id}`);

        setQuestionEditModal(qData);
        setQuestionOptionsModal(null);
    };
const onEditorStateChange= (e)=>{
    setEditor(e);
  const html = stateToHTML(e.getCurrentContent());
  setHiddenText( html);
    // console.log(e)
}
const onEditorStateChange2= (e)=>{
    setEditor2(e);
  const html = stateToHTML(e.getCurrentContent());
  setHiddenText2( html);
    // console.log(e)
}

    const handleRemoveQuestion = async (q) => {
        await axios.post('/api/v1/admin/question/remove', {
            questionId: q.id,
        });

        if (q.type.includes('calendar') || q.type.includes('time')) {
            const questionsArr = timeQuestions.filter((question) => question.id !== q.id);
            setTimeQuestions([...questionsArr]);
        } else {
            const questionsArr = serviceQuestions.filter((question) => question.id !== q.id);
            setServiceQuestions([...questionsArr]);
        }


        setAlert({
            color: 'danger',
            message: 'question successfully removed',
        });
        setQuestionOptionsModal(false);
        setQuestionOptionsModal(null);
        setQuestionEditModal(null);

    };

    const toggleTemplate = async (e, templateId) => {
        const isChecked = e.target.checked;

        await axios.post('/api/v1/admin/question/toggle-general', {
            add: isChecked,
            serviceId: params.id,
            templateId,
        });
    };

    const handleCopyQuestions = async () => {
        await axios.post('/api/v1/admin/service/copy', {
            serviceToCopyId,
            serviceId: params.id,
        });
        setRefresh(false)
        setTimeout(() => { setRefresh(null) }, 300)
    };

    const uploadImage = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            setService({
                ...service,
                imageUrl: data.image,
            });
        }
    };

    const removeServiceImage = async (path) => {
        axios.get(`api/upload/delete-image?path=${path}`).then((res)=>{
      
        })
        await axios.delete(`/api/v1/admin/service/${service.id}/image`);

        setService({
            ...service,
            imageUrl: null,
        });
    };

    const toggleAddition = async (e, questionName) => {
        if (e.target.checked) {
            await axios.post('/api/v1/admin/question/addition', {
                serviceId: params.id,
                questionName,
            });

            if (questionName === 'additional-t') {
                setAdditionalTime(true);
            }

            if (questionName === 'additional-b') {
                setAdditionalBudget(true);
            }
        } else {
            await axios.delete(`/api/v1/admin/question/addition/${params.id}/${questionName}`);

            if (questionName === 'additional-t') {
                setAdditionalTime(null);
            }

            if (questionName === 'additional-b') {
                setAdditionalBudget(null);
            }
        }
    };
    const handleSwitch = () => {
        setSwitch(!sw);
        setMsg('')
        axios.get(`/api/v1/service/set-popular/${params.id}`).then((res) => {
            if (res.status === 201) {
                console.log(res)
                setMsg("successfully saved!")
            } else {
                setSwitch(false)
                setMsg("Three services already selected !")
            }
        }).catch((err) => {
            setSwitch(false)
            setMsg("Three services already selected !")
        })
        setTimeout(() => { setMsg('') }, 2000)
    }

    const handleSwitchBenefit = () => {
        setSwitch2(!sb);
        setMsg2('')
        axios.get(`/api/v1/service/set-benefits/${params.id}`).then((res) => {
            if (res.status === 201) {
                console.log(res)
                setMsg2("successfully saved!")
            } else {
                setSwitch2(false)
                setMsg2("Something went wrong!")
            }
        }).catch((err) => {
            setSwitch2(false)
            setMsg2("Something went wrong!")
        })
        setTimeout(() => { setMsg2('') }, 2000)
    }

    return (
        <>
            {!service ? <div style={{ position: "absolute", left: "56%", top: "50%", transform: "translate(-50%, -50%)" }}><lottie-player src="loader.json" background="transparent" speed="1" style={{ width: "150px", height: "150px" }} loop autoplay></lottie-player>
                <p style={{ textAlign: "center", marginTop: "1rem", fontWeight: "bold", color: "purple" }}>Please Wait ...</p> </div> :
                <div>


                    <Row className="page-title">
                        <Col className="col-12">
                            <PageTitle
                                breadCrumbItems={[
                                    { label: 'Services', path: '/services' },
                                    { label: 'Service Details', path: '/service/:id', active: true },
                                ]}
                                title={'Service Details'}
                            />
                        </Col>
                    </Row>
                    {service && (
                        <>


                            <Card>

                                <CardBody className="p-4">
                                    <h4>{service.titleEN}</h4>
                                    {service.category && (
                                        <h6>
                                            Category: <Link to="/categories">{service.category.titleEN}</Link>
                                        </h6>
                                    )}
                                    <hr className="p-1" />
                                    <h5>Main Settings</h5>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="serviceOrder">Service Order</Label>
                                                <Input
                                                    name="serviceOrder"
                                                    id="serviceOrder"
                                                    placeholder="Order"
                                                    type="number"
                                                    style={{ width: 80 }}
                                                    value={service.order}
                                                    onChange={(e) => setService({ ...service, order: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <div className="mt-3">
                                                <h6 >Cost Estimate Banner</h6>
                                                <CustomInput
                                                    style={{ marginLeft: "15px", zIndex: "67" }}

                                                    size="sm"
                                                    type="switch"
                                                    name="Cost Estimate Banner"
                                                    label="show service"
                                                    checked={sw}
                                                    onChange={handleSwitch}
                                                    inline
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <span style={{ fontSize: "11px", color: message.includes("successfully") ? "green" : "red" }}>{message}</span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={3}>
                                            <div className="mt-3">
                                                <h6 >Service Benefit Banner</h6>
                                                <CustomInput
                                                    style={{ marginLeft: "15px", zIndex: "67" }}

                                                    size="sm"
                                                    type="switch"
                                                    name="Service Benefit Banner"
                                                    label="show service"
                                                    checked={sb}
                                                    onChange={handleSwitchBenefit}
                                                    inline
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <span style={{ fontSize: "11px", color: message2.includes("successfully") ? "green" : "red" }}>{message2}</span>
                                        </Col>
                                    </Row>
                                    <Row>

                                  

                                        <Col md={4}>
                                            <h6>Choose Category</h6>
                                            <Select
                                                placeholder="Categories"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                onChange={(e) => setService({ ...service, categoryId: e.value })}
                                                defaultValue={{
                                                    value: service.category ? service.category.id : '',
                                                    label: service.category ? service.category.titleEN : '',
                                                }}
                                                options={categories}></Select>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                    <Col md={4}>
                                            <FormGroup>
                                                <Label for="average_cost">Average Cost</Label>
                                                <Input
                                                    name="average_cost"
                                                    id="average_cost"
                                                    placeholder="0-0"
                                                    value={service.AvgCost}
                                                    onChange={(e) => setService({ ...service, AvgCost: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="english">English Title</Label>
                                                <Input
                                                    name="english"
                                                    id="english"
                                                    placeholder="English"
                                                    value={service.titleEN}
                                                    onChange={(e) => setService({ ...service, titleEN: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="uzbek">Uzbek Title</Label>
                                                <Input
                                                    name="uzbek"
                                                    id="uzbek"
                                                    placeholder="Uzbek"
                                                    value={service.titleUZ}
                                                    onChange={(e) => setService({ ...service, titleUZ: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="russian">Russian Title</Label>
                                                <Input
                                                    name="russian"
                                                    id="russian"
                                                    placeholder="Russian"
                                                    value={service.titleRU}
                                                    onChange={(e) => setService({ ...service, titleRU: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className="p-1" />
                                    <Row>
                                        <Col md={6}>
                                            <h5>Image</h5>
                                            <Row className="mt-4 ml-1">
                                                <div className="uploader-outer mr-2" style={{ width: 300, height: 195 }}>
                                                    <label htmlFor="choice-image" className="uploader">
                                                        <div className="inside">
                                                            <Plus />
                                                            <h4>Upload</h4>
                                                        </div>
                                                    </label>

                                                    <Input autocomplete="off" type="file" id="choice-image" onChange={uploadImage} />
                                                </div>
                                                {service.imageUrl && (
                                                    <div className="profile_image_preview" style={{ width: 300, height: 195 }}>
                                                        <img
                                                            src={ASSETS_URL + service.imageUrl}
                                                            className="rounded"
                                                            style={{ width: 300, height: 195 }}
                                                            alt="service"
                                                        />
                                                        <button type="button" onClick={()=>removeServiceImage(service?.imageUrl)}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24">
                                                                <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <h5>Keywords</h5>
                                            <Row className="mt-3 ml-1">
                                                {service.keywords &&
                                                    service.keywords.map((keyword, i) => (
                                                        <Button key={i} size="sm" color="soft-primary" className="mr-2">
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-1">{keyword}</span>
                                                                <span onClick={() => handleRemovingKeyword(keyword)}>
                                                                    <X size={12} />
                                                                </span>
                                                            </div>
                                                        </Button>
                                                    ))}
                                            </Row>
                                            <Row noGutters className="mt-3  ml-1">
                                                <Col md={9} className="pr-2">
                                                    <Input
                                                        name="keyword"
                                                        placeholder="Keyword"
                                                        value={keywordText}
                                                        onKeyPress={(event) =>
                                                            event.key === 'Enter' ? handleAddKeyword() : null
                                                        }
                                                        onChange={(e) => setKeywordText(e.target.value)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Button onClick={handleAddKeyword}>Add Keyword</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                        <h5>Hidden Text(Russian)</h5>
                                            <Row className="mt-3 ml-1">
                                        <Editor
                                       editorState={editorState}
                                        // toolbarClassName="toolbarClassName"
                                        // wrapperClassName="wrapperClassName"
                                        // editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            link: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                         
                                            history: { inDropdown: true },
                                          }}
                                                    />
                                                    </Row>
                                        </Col>
                                        <Col md={8}>
                                        <h5>Hidden Text(Uzbek)</h5>
                                            <Row className="mt-3 ml-1">
                                        <Editor
                                       editorState={editorState2}
                                        // toolbarClassName="toolbarClassName"
                                        // wrapperClassName="wrapperClassName"
                                        // editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange2}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            link: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                         
                                            history: { inDropdown: true },
                                          }}
                                                    />
                                                    </Row>
                                        </Col>
                                     
                                        <Col md={12} className="mt-4  ml-1">
                                        <h5>Meta Tag Description</h5>
                                            <Row className="mt-3 ml-1">
                                    <TextArea value={DescText} onChange={(e)=>{setDescText(e.target.value)}} style={{width:"65%",height:"14vh"}}/>
                                                    </Row>
                                        </Col>
                                  
                                    </Row>
                                    <Button onClick={handleSaveChanges} className="mt-4 width-lg" color="primary">
                                        Save Changes
                                    </Button>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className="p-4">
                                    <h4>Service Questions</h4>
                                    <Card>
                                        <CardBody>
                                            {alert && (
                                                <UncontrolledAlert color={alert.color}>{alert.message}</UncontrolledAlert>
                                            )}
                                            <Row>
                                                <Col md={6}>
                                                    <h5 className="mb-4">General Questions</h5>
                                                    {timeQuestions &&
                                                        timeQuestions.map((question, i) => {
                                                            const questionTitle = question.questionText.filter(
                                                                (q) => q.belongs === 'client'
                                                            )[0].eng;
                                                            return (
                                                                <Button
                                                                    onClick={() => handleOptionsModal(question)}
                                                                    key={i}
                                                                    className="mb-3 d-flex justify-content-center align-items-center"
                                                                    size="sm"
                                                                    color="soft-primary">
                                                                    {question.type.includes('calendar') && (
                                                                        <Calendar size={16} />
                                                                    )}
                                                                    {question.type.includes('time') && <Clock size={16} />}
                                                                    <span className="ml-2">{questionTitle}</span>
                                                                </Button>
                                                            );
                                                        })}
                                                    {templates &&
                                                        templates.map((question, i) => {
                                                            const questionTitle = question.questionText.filter(
                                                                (q) => q.belongs === 'client'
                                                            )[0].eng;

                                                            if (addedTemplates) {
                                                                const isAdded = addedTemplates.find(
                                                                    (template) => template.questionId === question.id
                                                                );

                                                                return (
                                                                    <CustomInput
                                                                        key={i}
                                                                        id={questionTitle + ' ' + i}
                                                                        className="mb-3 d-block"
                                                                        type="switch"
                                                                        label={questionTitle}
                                                                        onChange={(e) => toggleTemplate(e, question.id)}
                                                                        defaultChecked={!!isAdded}
                                                                    />
                                                                );
                                                            }
                                                            return null;
                                                        })}

                                                    <Button
                                                        onClick={() => setCalendarQuestionModal(true)}
                                                        color="primary"
                                                        className="mt-3">
                                                        Add General Question
                                                    </Button>

                                                    <h5 className="mt-4">Additional Options</h5>
                                                    <CustomInput
                                                        id="budget"
                                                        className="mb-1 d-block"
                                                        type="switch"
                                                        onChange={(e) => toggleAddition(e, 'additional-b')}
                                                        label="Budget Question"
                                                        checked={!!additionalBudget}
                                                    />
                                                    <CustomInput
                                                        id="time"
                                                        className="mb-1 d-block"
                                                        type="switch"
                                                        onChange={(e) => toggleAddition(e, 'additional-t')}
                                                        label="Time Question"
                                                        checked={!!additionalTime}
                                                    />
                                                </Col>
                                                <Col md={6}>

                                                    <h5 className="mb-4">Special Questions</h5>
                                                    <div ref={questionsRef}>
                                                        {modalData === false || questionOptionsModal === false || refresh === false ? null :
                                                            <ReactSortable
                                                                onEnd={handleNewSort}
                                                                list={tempQuestions || []}
                                                                animation={150}
                                                                setList={handleQuestionsReorder}
                                                                tag="div"
                                                            >
                                                                {tempQuestions ? tempQuestions
                                                                    .map((question) => {
                                                                        const questionTitle = question.questionText;
                                                                        const t = questionTitle[0].eng;

                                                                        return (
                                                                            <Button
                                                                                data-id={question.id}
                                                                                data-order={question.order}
                                                                                onClick={() => handlesetQuestionEditModal(question)}
                                                                                key={question.id}
                                                                                className="mb-3 d-flex align-items-center"
                                                                                size="sm"
                                                                                style={{ cursor: 'grab' }}
                                                                                color="soft-primary">
                                                                                <img
                                                                                    alt='sort icon'
                                                                                    src={SortIcon}
                                                                                    style={{ width: 16, height: 16 }}
                                                                                />
                                                                                {t}
                                                                            </Button>
                                                                        )
                                                                    }) : (
                                                                    <>
                                                                        {/* https://stackoverflow.com/questions/44493890/reactjs-how-to-set-disabled-option-in-a-dynamic-way-for-sortablejs */}
                                                                    </>
                                                                )}
                                                            </ReactSortable>
                                                        }
                                                    </div>
                                                    <Button onClick={() => { setModalData(true) }} color="primary" className="mt-3">
                                                        Add Special Question
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={10}>
                                            <Select
                                                onChange={(e) => {
                                                    setServiceToCopyId(e.value);
                                                }}
                                                menuPlacement="top"
                                                className="react-select drop-up"
                                                classNamePrefix="react-select"
                                                options={services}></Select>
                                        </Col>
                                        <Col md={2}>
                                            <Button onClick={handleCopyQuestions} disabled={!serviceToCopyId} color="primary">
                                                Copy Questions
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </>
                    )}
                    <EditQuestionOptions
                        data={questionOptionsModal}
                        setQuestionEditModal={setQuestionEditModal}
                        setTimeQuestionEditModal={setTimeQuestionEditModal}
                        setQuestionOptionsModal={setQuestionOptionsModal}
                        handleRemoveQuestion={handleRemoveQuestion}
                    />
                    <EditQuestionModal
                        activeService={params.id}
                        questionEditModal={questionEditModal}
                        setQuestionEditModal={setQuestionEditModal}
                        setAlert={setAlert}
                        setQuestions={setServiceQuestions}
                        questions={serviceQuestions}
                        templateQuestions={templateQuestions}
                        handleRemoveQuestion={handleRemoveQuestion}
                    />
                    <AddQuestionModal
                        activeService={params.id}
                        isOpen={modalData}
                        setModalData={setModalData}
                        toggle={toggle}
                        setAlert={setAlert}
                        setQuestions={setServiceQuestions}
                        questions={serviceQuestions}
                        templateQuestions={templateQuestions}
                    />
                    <AddCalendarQuestionModal
                        activeService={params.id}
                        calendarQuestionModal={calendarQuestionModal}
                        setCalendarQuestionModal={setCalendarQuestionModal}
                        timeQuestions={timeQuestions}
                        setTimeQuestions={setTimeQuestions}
                        setAlert={setAlert}
                        templateQuestions={templateQuestions}
                    />
                    <EditTimeQuestionModal
                        timeQuestionEditModal={timeQuestionEditModal}
                        setTimeQuestionEditModal={setTimeQuestionEditModal}
                        activeService={params.id}
                        timeQuestions={timeQuestions}
                        setTimeQuestions={setTimeQuestions}
                        setAlert={setAlert}
                        templateQuestions={templateQuestions}
                    />
                   
                </div>
            }
        </>
    );
};

export default EditService;
