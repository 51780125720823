import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    Row,
} from 'reactstrap';
import PageTitle from '../components/PageTitle';
import axios  from '../axios';




const DetailUserPage = () => {
    const [user, setUser] = useState(null);
   
    const params = useParams();
    const paramId = params.id
    useEffect(() => {
        const fetchUsers = async () => {
       
            const  {data} =    await axios.get(`api/v3/pros/${paramId}/admin/reviews`);
      setUser(data[0])
           
            
        };

        fetchUsers();
       

                },[paramId])

           
    return (

        <div>
            {!user ? <div style={{ position: "absolute", left: "56%", top: "50%", transform: "translate(-50%, -50%)" }}><lottie-player src="loader.json" background="transparent" speed="1" style={{ width: "150px", height: "150px" }} loop autoplay></lottie-player>
                <p style={{ textAlign: "center", marginTop: "1rem", fontWeight: "bold", color: "purple" }}>Please Wait ...</p> </div> :
                <>
                    <Row className="page-title">
                        <Col className="col-12">
                            <PageTitle
                                breadCrumbItems={[
                                    { label: 'Reviews', path: '/review' },
                                    { label: 'Review Details', path: '/review/:id', active: true },
                                ]}
                                title={'Review Details'}
                            />
                        </Col>
                    </Row>
                    <Card>
                        {user && (
                            <>

                                <Form>
                                    <CardBody className="p-4">
                                        <Row noGutters>
                                    
                                            <Col md={8} className="pl-4 pr-2">
                                                <Row noGutters>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="name"> User Name</Label>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            placeholder="Name"
                                                            value={`${user?.user?.firstName} ${user?.user?.lastName === "null" ? '' : user?.user?.lastName == null ? "" : user?.user?.lastName}`}
                                                            required
                                                            readOnly
                                                        />
                                                    </Col>

                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="phone">Pro Name</Label>

                                                        <Input
                                                            name="Mobile_phone"
                                                            id="phone"
                                                            placeholder="Phone"
                                                            value={user?.pro?.name ?user?.pro?.name : 'N/A'}
                                                            readOnly
                                                            required

                                                        />

                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="email">Comment</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={user?.comment? user.cooment : 'N/A'}
                                                            readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="email">Respond</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={user?.respond? user.respond : 'N/A'}
                                                            readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="email">Service</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={user?.service?.titleEN? user?.service?.titleEN : 'N/A'}
                                                            readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col md={6} className="pr-4 mb-4">
                                                        <Label for="website">Post Date</Label>
                                                        <Input
                                                            type="url"
                                                            name="overview_website"
                                                            id="website"
                                                            placeholder="Date"
                                                            value={(new Date(user?.createdAt).getDate() < 10 ? '0' : '') + new Date(user?.createdAt).getDate() + "." + (new Date(user?.createdAt).getMonth() < 10 ? '0' : '') + new Date(user?.createdAt).getMonth() + "." + new Date(user?.createdAt).getFullYear() + "- " + new Date(user?.createdAt).getHours() + ":" + (new Date(user?.createdAt).getMinutes() < 10 ? '0' : '') + new Date(user?.createdAt).getMinutes()}

                                                        />
                                                    </Col>
                                                    <Col md={12} className="pr-4 mb-4">
                                                        <Label for="phone">Ratings</Label>
                                                     
                                                            <Input
                                                                style={{ cursor: "pointer", color: "blue" }}
                                                                value={user.rate ?user.rate: "N/A"}
                                                                required
                                                            />

                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Form>
                            </>
                        )}
                    </Card>
                  
                </>
            }
        </div>

    );
};

export default DetailUserPage;
