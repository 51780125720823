import { v4 as uuid } from 'uuid';
import { Question } from "../../types"
import {
    SET_CLIENT_CHOICE_TYPE,
    SET_MODAL_STATE_QUESTION,
    SET_PRO_CHOICE_TYPE,
    SET_CHOICES,
    ADD_CHOICE,
    REMOVE_CHOICE,
    DUPLICATE_CHOICE,
    ADD_PRO_CHOICE,
    HANDLE_CHOICE_CHANGE,
    REMOVE_PRO_CHOICE,
    RESET_DELETED_CHOICES,
    INIT_CHOICES,
    REORDER_CHOICES,
} from "./actionTypes"
import { ChangeKeys, ChoiceType, ModalStateQuestion, StateChoice } from "./types"

export interface SetChoiceType {
    type: (typeof SET_CLIENT_CHOICE_TYPE | typeof SET_PRO_CHOICE_TYPE),
    payload: ChoiceType;
};

export const setClientChoiceType = (payload: ChoiceType): SetChoiceType => {
    return { type: SET_CLIENT_CHOICE_TYPE, payload }
}



export const setProChoiceType = (payload: ChoiceType): SetChoiceType => {
    return { type: SET_PRO_CHOICE_TYPE, payload }
}

export interface SetModalStateQuestion {
    type: typeof SET_MODAL_STATE_QUESTION,
    payload: ModalStateQuestion;
};

export const setModalStateQuestion = (payload: ModalStateQuestion): SetModalStateQuestion => {
    return { type: SET_MODAL_STATE_QUESTION, payload }
}

export interface SetChoices {
    type: typeof SET_CHOICES | typeof INIT_CHOICES | typeof REORDER_CHOICES;
    payload: StateChoice[];
}

export const reorderChoices = (choices: StateChoice[]): SetChoices => {
    const orderedChoices = choices.map(({ order, edited, proSideEdited, ...choice }, index): StateChoice =>
        ({ ...choice, order: index, reordered: true }));
    return { type: REORDER_CHOICES, payload: orderedChoices };
}

export const resetChoices = (): SetChoices => {
    return { type: SET_CHOICES, payload: [] };
}

export interface ResetDeletedChoices {
    type: typeof RESET_DELETED_CHOICES,
    payload: StateChoice[];
}

export const resetDeletedChoices = (): ResetDeletedChoices => {
    return { type: RESET_DELETED_CHOICES, payload: [] };
}



export const initChoices = (question: Question): SetChoices => {
    const clientChoices = question.choiceText.filter((c) => c.belongs === 'client');
    let tempChoices: StateChoice[] = [];
    clientChoices.forEach((choice, index) => {
        const proChoice = question.choiceText.find((ch) => ch.id === choice.proChoiceId);

        const order = choice.order === null ? index : choice.order;
        if (proChoice) {
            const choiceObj: StateChoice = {
                id: uuid(),
                idCopy: choice.id,
                reordered: typeof choice.order !== 'undefined' ? undefined : true,
                order,
                clientEnglish: choice.eng,
                clientRussian: choice.ru,
                clientUzbek: choice.uz,
                choiceImage: choice.choice_image,
                proChoices: true,
                proId: proChoice.id,
                proEnglish: proChoice.eng,
                proRussian: proChoice.ru,
                proUzbek: proChoice.uz,
            };

            tempChoices.push(choiceObj);
        } else {
            const choiceObj: StateChoice = {
                id: uuid(),
                idCopy: choice.id,
                reordered: typeof choice.order !== 'undefined' ? undefined : true,
                order,
                clientEnglish: choice.eng,
                clientRussian: choice.ru,
                clientUzbek: choice.uz,
                choiceImage: choice.choice_image,
                proChoices: false,
                proEnglish: null,
                proRussian: null,
                proUzbek: null,
            };
            
            tempChoices.push(choiceObj);
        }
    });

    const sortedChoices = tempChoices.sort((a, b) => {
        if(a.order !== null && b.order !== null){
            if(a.order < b.order){
                return -1;
            }
            if(a.order > b.order){
                return 1;
            }
        }
        return 0;
    })

    return { type: INIT_CHOICES, payload: sortedChoices };
}

export interface AddChoice {
    type: typeof ADD_CHOICE;
    payload: StateChoice;
}

export const addChoice = (): AddChoice => {
    const choice: StateChoice = {
        id: uuid(),
        clientEnglish: '',
        clientRussian: '',
        clientUzbek: '',
        proChoices: false,
        proEnglish: null,
        proRussian: null,
        proUzbek: null,
        order: null,
        new: true,
    }

    return { type: ADD_CHOICE, payload: choice }
}

export interface AddProChoice {
    type: typeof ADD_PRO_CHOICE;
    payload: number;
}

export const addProChoice = (index: number): AddProChoice => {
    return { type: ADD_PRO_CHOICE, payload: index };
};

export interface RemoveChoice {
    type: typeof REMOVE_CHOICE | typeof REMOVE_PRO_CHOICE,
    payload: number;
}

export const removeChoice = (index: number): RemoveChoice => {
    return {
        type: REMOVE_CHOICE,
        payload: index,
    }
}

export interface DuplicateChoice {
    type: typeof DUPLICATE_CHOICE;
    payload: number;
}

export const duplicateChoice = (index: number): DuplicateChoice => {
    return { type: DUPLICATE_CHOICE, payload: index }
}

export interface HandleChoiceChange {
    type: typeof HANDLE_CHOICE_CHANGE;
    payload: {
        updateKey: ChangeKeys;
        index: number;
        pro: boolean;
        changeValue: string;
    }
}

export const handleChoiceChange = (updateKey: ChangeKeys, changeValue: string, index: number, pro: boolean): HandleChoiceChange => {
   return { type: HANDLE_CHOICE_CHANGE, payload: { updateKey, index, pro, changeValue } }
};


export const removeProChoice = (index: number): RemoveChoice => {
    return { type: REMOVE_PRO_CHOICE, payload: index }
}

export const addTemplateChoices = (template: Question): SetChoices => {
    const clientChoices = template.choiceText.filter((c) => c.belongs === 'client');

    const choicesToSet: StateChoice[] = clientChoices.map((currChoice, index) => {
        const proChoice = template.choiceText.find((ch) => ch.id === currChoice.proChoiceId);

        const order = currChoice.order || index;
        if (proChoice) {
            const ProChoiceObj: StateChoice = {
                id: uuid(),
                idCopy: currChoice.id,
                order,
                clientEnglish: currChoice.eng,
                clientRussian: currChoice.ru,
                clientUzbek: currChoice.uz,
                proChoices: true,
                proId: proChoice.id,
                proEnglish: proChoice.eng,
                proRussian: proChoice.ru,
                proUzbek: proChoice.uz,
            };

            return ProChoiceObj;
        } else {
            const clientChoiceObj: StateChoice = {
                id: uuid(),
                idCopy: currChoice.id,
                order,
                clientEnglish: currChoice.eng,
                clientRussian: currChoice.ru,
                clientUzbek: currChoice.uz,
                proChoices: false,
                proEnglish: null,
                proRussian: null,
                proUzbek: null,
            };

            return clientChoiceObj;
        }
    });

    return { type: SET_CHOICES, payload: choicesToSet }
}