import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageTitle from '../components/PageTitle';

const Dictionary = () => {
    return (
        <div>
            <Row className="page-title">
                <Col className="col-12">
                    <PageTitle
                        breadCrumbItems={[{ label: 'Dictionary', path: '/dictionary', active: true }]}
                        title={'Dictionary'}
                    />
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <h1>hey</h1>
                </CardBody>
            </Card>
        </div>
    );
};

export default Dictionary;
