import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Redirect, Route } from 'react-router-dom';
import DeletedPros from '../pages/DeletedPRo';
import Dictionary from '../pages/Dictionary';
import PendingServices from '../pages/PendingServices';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// users
const Users = React.lazy(() => import('../pages/Users'));
const DelUsers = React.lazy(() => import('../pages/deletedUsers'));
const Orders = React.lazy(() => import('../pages/Orders'));

// pros
const Pros = React.lazy(() => import('../pages/Pros'));

// category
const Categories = React.lazy(() => import('../pages/Categories'));
const Services = React.lazy(() => import('../pages/Services'));
const Questions = React.lazy(() => import('../pages/Questions'));
const Templates = React.lazy(() => import('../pages/Templates'));
const Review = React.lazy(()=>import("../pages/Reviews"))

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    // badge: {
    //     variant: 'success',
    //     text: '1',
    // },
    component: Dashboard,
    roles: ['Admin'],
    route: PrivateRoute,
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
    ],
};

// users
const usersRoutes = {
    path: '/users',
    name: 'Users',
    icon: FeatherIcon.User,
    header: 'Users',
    component: Users,
    roles: ['Admin'],
    route: PrivateRoute,
};
const DeletedUserRoutes = {
    path: '/users-deleted',
    name: ' Deleted Users',
    icon: FeatherIcon.Delete,
    component: DelUsers,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};

const ordersRoutes = {
    path: '/orders',
    exact: true,
    name: 'Orders',
    icon: FeatherIcon.CreditCard,
    badge: {
        variant: 'primary',
        text: '23',
    },
    component: Orders,
    roles: ['Admin'],
    route: PrivateRoute,
};

// pros
const prosRoutes = {
    path: '/pros',
    name: 'Professionals',
    header: 'Pros',
    icon: FeatherIcon.Tool,
    component: Pros,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};
const prosReviewRoutes = {
    path: '/review',
    name: 'Reviews',
    icon: FeatherIcon.Star,
    component: Review,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};

const DeletedprosRoutes = {
    path: '/pros-deleted',
    name: ' Deleted Professionals',
    icon: FeatherIcon.Delete,
    component: DeletedPros,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};


// category
const categoryRoutes = {
    path: '/categories',
    name: 'Categories',
    header: 'Category',
    icon: FeatherIcon.Grid,
    component: Categories,
    route: PrivateRoute,
    roles: ['Admin'],
};

const servicesRoutes = {
    path: '/services',
    name: 'Services',
    icon: FeatherIcon.Shield,
    component: Services,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};

const pendingServicesRoutes = {
    path: '/pending-services',
    name: 'Pending Services',
    icon: FeatherIcon.CheckCircle,
    component: PendingServices,
    route: PrivateRoute,
    roles: ['Admin'],
    exact: true,
};

const questionsRoutes = {
    path: '/questions',
    name: 'Questions',
    icon: FeatherIcon.List,
    component: Questions,
    route: PrivateRoute,
    roles: ['Admin'],
};

const templatesRoutes = {
    path: '/templates',
    name: 'Templates',
    icon: FeatherIcon.FilePlus,
    component: Templates,
    route: PrivateRoute,
    roles: ['Admin'],
};

// Dictionary
const dictionaryRoutes = {
    path: '/dictionary',
    name: 'Dictionary',
    header: 'Dictionary',
    icon: FeatherIcon.BookOpen,
    component: Dictionary,
    route: PrivateRoute,
    roles: ['Admin'],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    dashboardRoutes,
    usersRoutes,
    DeletedUserRoutes,
    prosReviewRoutes,
    ordersRoutes,
    prosRoutes,
    DeletedprosRoutes,
    categoryRoutes,
    servicesRoutes,
    pendingServicesRoutes,
    questionsRoutes,
    templatesRoutes,
    dictionaryRoutes,
    authRoutes,
];

const authProtectedRoutes = [
    dashboardRoutes,
    usersRoutes,
    DeletedUserRoutes,
    ordersRoutes,
    prosRoutes,
    DeletedprosRoutes,
    categoryRoutes,
    prosReviewRoutes,
    servicesRoutes,
    pendingServicesRoutes,
    questionsRoutes,
    templatesRoutes,
    dictionaryRoutes,
];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };