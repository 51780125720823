import axios from 'axios';

export const ASSETS_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/' : 'https://api.topx.uz/'

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://api.topx.uz',
    credentials: true,
    withCredentials: true,
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
