import { FilePdfOutlined, DeleteFilled } from '@ant-design/icons';
import React, { useState } from 'react'
import { Button } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { PreviewRowContainer, PreviewImage, PreviewContainer, UploadDocumentsContainer } from './style';
import { getFileExtension } from './utils';
import axios from '../../../axios';
import { useParams } from 'react-router-dom';
import { VerificationDocument } from '../../../types';

interface DocumentsUploadProps {
    setDocuments(documents: VerificationDocument[]): void;
}

const DocumentsUpload = ({ setDocuments }: DocumentsUploadProps) => {
    const { id: proId } = useParams<{ id: string }>();
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<File[]>([])

    const onDrop = (acceptedFiles: File[]) => {
        setFiles([...files, ...acceptedFiles]);
    };

    const handleSubmit = async () => {
        setUploading(true);
        const formData = new FormData();
        for (const file of files){
            formData.append('files', file);
        }
        const { data: newDocuments} = await axios.post<VerificationDocument[]>(`/api/v2/admin/pro/verification-documents/${proId}`, formData);

        setUploading(false);
        setFiles([]);
        setDocuments(newDocuments);
    }

    const handleFileRemove = (index: number) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/*', 'application/pdf'],
        multiple: true,
    });
    return (
        <UploadDocumentsContainer>
            <h5>Upload verification documents</h5>
            <div className="dropzone text-center">
                <div className="dz-message py-5 needsclick" {...getRootProps()}>
                    <input multiple type='file' {...getInputProps()} />
                    <i className="h1 text-muted uil-cloud-upload"></i>
                    <div className="text-muted font-13">Drop files here or click to upload.</div>
                </div>
            </div>
            <PreviewContainer>
                {files.map((file, index) => (
                    <PreviewRowContainer>
                        {getFileExtension(file.name) === 'pdf'
                            ? <FilePdfOutlined style={{ fontSize: 40 }} />
                            : <PreviewImage alt={file.name} src={URL.createObjectURL(file)} />
                        }
                        {file.name}
                        <Button onClick={() => handleFileRemove(index)} color='danger'>
                            <DeleteFilled />
                        </Button>
                    </PreviewRowContainer>
                ))}
            </PreviewContainer>
            <Button
                color='primary'
                disabled={files.length === 0 || uploading}
                onClick={handleSubmit}
            >
                Upload
            </Button>
        </UploadDocumentsContainer>
    )
}

export default DocumentsUpload
