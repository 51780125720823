import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone,SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Edit3, MinusCircle, Plus, CornerUpLeft } from 'react-feather';
import { useHistory } from 'react-router';
import Select from 'react-select';
import"./user.css";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    CardBody,
    Col,
    CustomInput,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledAlert,
    UncontrolledDropdown,
} from 'reactstrap';
import axios, { ASSETS_URL } from '../axios';
import EditProModal from '../components/EditProModal';
import PageTitle from '../components/PageTitle';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input
            type="select"
            name="select"
            id="no-entries"
            className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>;
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);
const handleNextPage = (pageProp, myPage) => {
    console.log("page:", pageProp, "myPage:", myPage, "type of myPage:", typeof myPage);
    let newPage = parseInt(myPage);
    pageProp.onPageChange(newPage ? newPage : 1);
}
const Table = ({ pros, setAddPro, generalAlert, handleDeletePro, handleRestorePro, isLoading }) => {
    const [size,setSize] = useState(10);


    const option = {
        custom: true,
        alwaysShowAllBtns: true,
        sizePerPage: size,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ],
        
    }
    const imageFormatter = (cell) => {
        return <img src={ASSETS_URL + cell} className="avatar-xs rounded" alt="user avatar" />;
    };

    function actionsFormatter(cell, row) {
        const deleteProAction = () => handleDeletePro(row?.id);
        const restoreProAction = (() => handleRestorePro(row?.id)) || (() => {});
        const finalAction = row?.deletedAt ? restoreProAction : deleteProAction;
        const finalActionText = row?.deletedAt ? 'Confirm Restore' : 'Confirm Delete';
        return (
            <span>
                <Link to ={`/pros/${row?.id}`} >
                    <Button className="mr-2 p-1" size="sm" color="primary">
                        <Edit3 color="white" size={16} />
                    </Button>
                </Link>
                <UncontrolledDropdown size="sm" className="d-inline">
                    <DropdownToggle color={row?.deletedAt ? 'success' : 'danger'}>
                        {row?.deletedAt
                            ? <CornerUpLeft size={16} />
                            : <MinusCircle size={16} />
                        }
                        
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem className="text-danger">
                            <span onClick={finalAction} className="mr-2 p-1">
                                {finalActionText}
                            </span>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Cancel</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {/* <Button className="p-1" size="sm" color="danger">
                    <MinusCircle size={16} />
                </Button> */}

           
            </span>

        );
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'profile_image',
            text: 'Image',
            sort: false,
            formatter: imageFormatter,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            sort: true,
            formatter: actionsFormatter,
        },
    ];

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'd',
        },
    ];
    const { SearchBar } = Search;
    useEffect(()=>{
        document.getElementById("no-entries")?.addEventListener("change", function() {
     setSize(document.getElementById("no-entries").value);});
    
            return () => {
                document.getElementById("no-entries")?.removeEventListener("change",function() {
              setSize(document.getElementById("no-entries").value);});
        
              }
    })
    return (
        <>
        {!pros?<div style={{position:"absolute",left:"56%",top:"50%",transform:"translate(-50%, -50%)"}}><lottie-player src="loader.json"  background="transparent"  speed="1"  style={{width:"150px",height:"150px"}} loop  autoplay></lottie-player>
        <p style={{textAlign:"center",marginTop:"1rem",fontWeight:"bold", color:"purple"}}>Please Wait ...</p> </div>

        : pros.length>0?
        <Card>
            <CardBody>
                {generalAlert && (
                    <UncontrolledAlert color={generalAlert.color}>{generalAlert.message}</UncontrolledAlert>
                )}
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={pros}
                    columns={columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {(props) => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Row className="align-items-center pl-2">
                                        <p className="m-0 mr-2">Search: </p>
                                        <SearchBar {...props.searchProps} />
                                    </Row>
                                </Col>
                             
                            </Row>
                            <PaginationProvider pagination={paginationFactory(option)}>
                                        {
                                            ({
                                                paginationProps,
                                                paginationTableProps
                                            }) => (<>
                                                <BootstrapTable
                                                    keyField="id"
                                 
                                                    
                                                    {...props.baseProps}
                                                    bordered={true}
                                                    hover
                                                    wrapperClasses="table-responsive"

                                                 
                                                    columns={columns}
                                                    {...paginationTableProps}
                                                />
                                               
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                                <div  id="jump" style={{ display: "flex" }}>
                                                    <span style={{ marginTop: "0.6rem" }}>Jump to Page : </span>
                                                    <Input style={{ width: "12%", marginLeft: "1rem" }} type="text" onChange={(e) => { handleNextPage(paginationProps, e.target.value) }} />
                                                </div>
                                                <PaginationListStandalone
                                                    style={{ float: "right" }}
                                                    {...paginationProps}
                                                />
                                            </>
                                            )
                                        }
                                    </PaginationProvider>
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>:<h3>There's No Deleted Professional</h3>
}
</>
    );
};

const DeletedPros = () => {
    const [addPro, setAddPro] = useState(false);
    const[refresh,setRefresh] = useState(false)
    const [modalData, setModalData] = useState({
        name: '',
        email: '',
        hired: '',
        profilePicutre: '',
        employees: 0,
        yearFounded: 2021,
        phone: '',
        website: '',
        address: '',
        coins: 0,
        description: '',
        confirmed: false,
        cash: true,
        uzcard: false,
        humo: false,
        payme: false,
        click: false,
        images: [],
    });
    const [pros, setPros] = useState();
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [generalAlert, setGeneralAlert] = useState(null);
    const [editProData, setEditProData] = useState(null);
    const [prourl, setProfileUrl] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const fetchPros = async () => {
            const { data } = await axios.get('/api/v2/admin/pro', { params: { deletedOnly: true } });
            setPros(data);

            const services = await axios.get('/api/v1/admin/service');
            const options = services.data.map((service) => {
                return {
                    value: service.id,
                    label: service.titleEN,
                };
            });

            setServices(options);
        };

        fetchPros();
    }, [refresh,addPro]);

    const toggle = () => {
        setAddPro(null);
    };

    const handleChange = (e) => {
        setModalData({
            ...modalData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!modalData.services) {
            return setAlert({
                color: 'danger',
                message: 'please add at least one service',
            });
        }

        const payments = [];

        if (modalData.cash) {
            payments.push('Cash');
        }

        if (modalData.uzcard) {
            payments.push('UzCard');
        }

        if (modalData.humo) {
            payments.push('Humo');
        }

        if (modalData.payme) {
            payments.push('Payme');
        }

        if (modalData.click) {
            payments.push('Click');
        }

        const services = modalData.services.map((service) => service.value);

        const overview = {};

        overview.hired = modalData.hired;
        overview.confirmed = modalData.confirmed;
        overview.count_employees = modalData.employees;
        overview.year_founded = modalData.yearFounded;
        overview.website = modalData.website;
        overview.address = modalData.address;
        overview.phone = modalData.phone;

        const body = {
            name: modalData.name,
            email: modalData.email,
            description: modalData.description,
            profilePicutre: modalData.profilePicutre || null,
            images: modalData.images || null,
            payments,
            services,
            overview,
        };

        try {
            const { data } = await axios.post('/api/v2/admin/pro', body);
            setAddPro(null);
            setGeneralAlert({
                color: 'success',
                message: data.message,
            });
        } catch (err) {
            if (err.response.data.errors) {
                setAlert({
                    color: 'danger',
                    message: err.response.data.errors[0].msg,
                });
            }
        }
    };

    const uploadAvatar = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);
        setProfileUrl( window.URL.createObjectURL(e.target.files[0]))

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            setModalData({
                ...modalData,
                profilePicutre: data.image,
            });
        }
    };

    const uploadImage = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            const images = modalData.images;

            images.push(data.image);

            setModalData({
                ...modalData,
                images,
            });
        }
    };

    const handleDeletePro = async (pro) => {
        await axios.delete(`/api/v1/admin/pro/${pro}`);
        setRefresh(!refresh);
        const prosList = pros.filter((p) => p.id !== pro);

        setPros([...prosList]);

        setGeneralAlert({
            color: 'danger',
            message: 'Pro deleted successfully!',
        });
    };

    const handleRestorePro = async (pro) => {
        setIsLoading(true);
        await axios.post(`/api/v1/admin/pro/restore/${pro}`)
        setRefresh(!refresh);

        setGeneralAlert({
            color: 'success',
            message: 'Pro restored successfully!',
        });
    };

    return (
        <div>
            <Row className="page-title">
                <Col className="col-12">
                    <PageTitle
                        breadCrumbItems={[{ label: 'Pros', path: '/pros', active: true }]}
                        title={' Deleted Professionals'}
                    />
                </Col>
            </Row>
            <Table
                handleDeletePro={handleDeletePro}
                handleRestorePro={handleRestorePro}
                isLoading={isLoading}
                generalAlert={generalAlert}
                pros={pros} setAddPro={setAddPro}
            />
            <Modal isOpen={addPro} size="xl">
                <ModalHeader toggle={toggle}>Add Professional</ModalHeader>
                <Form action="POST" onSubmit={handleSubmit}>
                    <ModalBody className="p-4">
                        {alert && <UncontrolledAlert color={alert.color}>{alert.message}</UncontrolledAlert>}
                        <Row noGutters>
                            <Col md={4} className="pr-2 pl-2">
                                <h6>Avatar</h6>
                                <div className="uploader-outer">
                                    <label htmlFor="choice-image" className="uploader">
                                        <div className="inside">
                                            <Plus />
                                            <h4>Upload</h4>
                                        </div>
                                    </label>
                                    <Input autocomplete="off"type="file" onChange={uploadAvatar} id="choice-image" />
                                </div>
                                {modalData.profilePicutre && (
                                    <img
                                        src={prourl? prourl:'https://api.topx.uz/' + modalData.profilePicutre}
                                        className="w-100"
                                        style={{ objectFit: 'cover' }}
                                        alt="profile"
                                    />
                                )}
                                <div className="mt-4">
                                    <h6>Confirmed</h6>
                                    <CustomInput
                                        checked={modalData.confirmed}
                                        onChange={() => setModalData({ ...modalData, confirmed: true })}
                                        type="radio"
                                        name="confirmed"
                                        id="confirmedYes"
                                        label="Yes"
                                        inline
                                    />
                                    <CustomInput
                                        checked={!modalData.confirmed}
                                        onChange={() => setModalData({ ...modalData, confirmed: false })}
                                        type="radio"
                                        name="confirmed"
                                        id="confirmedNo"
                                        label="No"
                                        inline
                                    />
                                </div>
                                <div className="mt-3">
                                    <h6>Payments</h6>
                                    <Row>
                                        <Col md={6}>
                                            <CustomInput
                                                checked={modalData.cash}
                                                onChange={(state) =>
                                                    setModalData({ ...modalData, cash: state.target.checked })
                                                }
                                                className="mb-2"
                                                type="switch"
                                                id="cash"
                                                label="Cash"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <CustomInput
                                                checked={modalData.uzcard}
                                                onChange={(state) =>
                                                    setModalData({ ...modalData, uzcard: state.target.checked })
                                                }
                                                className="mb-2"
                                                type="switch"
                                                id="uzcard"
                                                label="UzCard"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <CustomInput
                                                checked={modalData.humo}
                                                onChange={(state) =>
                                                    setModalData({ ...modalData, humo: state.target.checked })
                                                }
                                                className="mb-2"
                                                type="switch"
                                                id="humo"
                                                label="Humo"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <CustomInput
                                                checked={modalData.payme}
                                                onChange={(state) =>
                                                    setModalData({ ...modalData, payme: state.target.checked })
                                                }
                                                className="mb-2"
                                                type="switch"
                                                id="payme"
                                                label="Payme"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <CustomInput
                                                checked={modalData.click}
                                                onChange={(state) =>
                                                    setModalData({ ...modalData, click: state.target.checked })
                                                }
                                                className="mb-2"
                                                type="switch"
                                                id="click"
                                                label="Click"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={8} className="pl-4 pr-2">
                                <Row noGutters>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="name">Name</Label>
                                        <Input
                                            name="name"
                                            id="name"
                                            placeholder="Name"
                                            value={modalData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            value={modalData.email}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="hired">Hired</Label>
                                        <Input
                                            type="number"
                                            name="hired"
                                            id="hired"
                                            placeholder="Hired"
                                            value={modalData.hired}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="services">Services</Label>
                                        <Select
                                            isMulti={true}
                                            placeholder="Services"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            onChange={(services) => setModalData({ ...modalData, services })}
                                            required
                                            options={services}></Select>
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="employees">Employees Count</Label>
                                        <Input
                                            type="number"
                                            name="employees"
                                            id="employees"
                                            placeholder="Employees Count"
                                            value={modalData.employees}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="yearFounded">Year Founded</Label>
                                        <Input
                                            type="number"
                                            name="yearFounded"
                                            id="yearFounded"
                                            placeholder="Year Founded"
                                            value={modalData.yearFounded}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="phone">Phone</Label>
                                        <Input
                                            name="phone"
                                            id="phone"
                                            placeholder="Phone"
                                            value={modalData.phone}
                                            required
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="website">Website</Label>
                                        <Input
                                            type="url"
                                            name="website"
                                            id="website"
                                            placeholder="Website"
                                            value={modalData.website}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-4">
                                        <Label for="address">Address</Label>
                                        <Input
                                            name="address"
                                            id="address"
                                            placeholder="Address"
                                            value={modalData.address}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-2">
                                        <Label for="coins">Coins</Label>
                                        <Input
                                            type="number"
                                            name="coins"
                                            id="coins"
                                            placeholder="coins"
                                            value={modalData.coins}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} className="pr-4 mb-2">
                                        <Label for="description">Description</Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            id="description"
                                            placeholder="Description"
                                            value={modalData.description}
                                            required
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6} />
                                    <h6>Photos</h6>
                                    <Col md={12} className="pr-1">
                                        <div className="uploader-outer">
                                            <label htmlFor="pro-images-uploader" className="uploader">
                                                <div className="inside">
                                                    <Plus />
                                                    <h4>Upload</h4>
                                                </div>
                                            </label>
                                            <Input autocomplete="off"type="file" onChange={uploadImage} id="pro-images-uploader" />
                                            <Row>
                                                {modalData.images.map((image, i) => (
                                                    <Col md={2}>
                                                        <img
                                                            key={i}
                                                            src={'https://api.topx.uz/' + image}
                                                            className="w-100"
                                                            style={{ height: 90, objectFit: 'cover' }}
                                                            alt="pro service"
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="ml-1" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Add Professional
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <EditProModal
                services={services}
                uploadAvatar={uploadAvatar}
                editProData={editProData}
                setEditProData={setEditProData}
                alert={alert}
                pros={pros}
                setPros={setPros}
            />
        </div>
    );
};

export default DeletedPros;
