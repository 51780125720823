import React, { useEffect, useState } from 'react';
import { Layers, Plus } from 'react-feather';
import Select from 'react-select';
import {
    Button,
    ButtonGroup,
    Col,
    CustomInput,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledAlert,
} from 'reactstrap';
import axios, { ASSETS_URL } from '../axios';

const AddQuestionModal = ({
    isOpen,
    toggle,
    activeService,
    setModalData,
    setAlert,
    questions,
    setQuestions,
    templateQuestions,
}) => {
    const [question, setQuestion] = useState({
        filter: false,
        proPage: false,
        skip: false,
        clientOnly: false,
        inputBar: false,
        showImages: false,
        clientTitleEnglish: '',
        clientTitleRussian: '',
        clientTitleUzbek: '',
        proTitleEnglish: '',
        proTitleRussian: '',
        proTitleUzbek: '',
        descriptionEnglish: '',
        descriptionRussian: '',
        descriptionUzbek: '',
    });

    const [templateChoices, setTemplateChoices] = useState(null);
    const [clientChoiceType, setClientChoiceType] = useState('single');
    const [proChoiceType, setProChoiceType] = useState('multiple');
    const [choices, setChoices] = useState([
        {
            choiceImage: '',
            clientEnglish: '',
            clientRussian: '',
            clientUzbek: '',
            proChoices: false,
            proEnglish: null,
            proRussian: null,
            proUzbek: null,
        },
    ]);

    const [modalAlert, setModalAlert] = useState(null);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {


        setRefresh(true)
        if (templateQuestions) {
            const options = templateQuestions.map((temp) => {
                return {
                    value: temp.id,
                    label: temp.questionText.filter((q) => q.belongs === 'client')[0].eng,
                };
            });

            setTemplateChoices(options);
            setRefresh(false)
        }
    }, [templateQuestions]);

    const addNewChoice = () => {
        choices.push({
            choiceImage: '',
            clientEnglish: '',
            clientRussian: '',
            clientUzbek: '',
            proEnglish: null,
            proRussian: null,
            proUzbek: null,
        });

        setChoices([...choices]);
    };

    const removeChoice = (index) => {
        if (choices.length > 1) {
            choices.splice(index, 1);
            setChoices([...choices]);
        }
    };

    const duplicateChoice = (index) => {
        choices[index].proEnglish = choices[index].clientEnglish;
        choices[index].proRussian = choices[index].clientRussian;
        choices[index].proUzbek = choices[index].clientUzbek;
        choices[index].proChoices = true;

        setChoices([...choices]);
    };

    const addProChoice = (index) => {
        choices[index].proEnglish = '';
        choices[index].proRussian = '';
        choices[index].proUzbek = '';
        choices[index].proChoices = true;

        setChoices([...choices]);
    };

    const handleChange = (e, i) => {
        choices[i][e.target.name] = e.target.value;
        setChoices([...choices]);
    };

    const handleTitleChange = (e) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !question.clientTitleEnglish ||
            !question.proTitleEnglish ||
            !question.clientTitleRussian ||
            !question.proTitleRussian ||
            !question.clientTitleUzbek ||
            !question.proTitleRussian
        ) {
            return setModalAlert({
                color: 'danger',
                message: 'Titles are required.',
            });
        }

        const body = {
            options: {
                skip: question.skip,
                filter: question.filter,
                proPage: question.proPage,
                clientOnly: question.clientOnly,
                inputBar: question.inputBar,
                showImages: question.showImages,
            },
            title: {
                clientEnglish: question.clientTitleEnglish,
                clientRussian: question.clientTitleRussian,
                clientUzbek: question.clientTitleUzbek,
                proEnglish: question.proTitleEnglish,
                proRussian: question.proTitleRussian,
                proUzbek: question.proTitleUzbek,
            },
            description: {
                english: question.descriptionEnglish,
                russian: question.descriptionRussian,
                uzbek: question.descriptionUzbek,
            },
            clientChoiceType,
            proChoiceType,
            choices,
            serviceId: activeService,
        };

        const res = await axios.post('/api/v2/admin/questions', body);

        if (res.status === 201) {
            questions.unshift(res.data.question);
            setQuestions([...questions]);
            setModalData(false);
            setAlert({
                color: 'success',
                message: 'question successfully added',
            });
            setQuestion({
                filter: false,
                proPage: false,
                skip: false,
                clientOnly: false,
                inputBar: false,
                showImages: false,
                clientTitleEnglish: '',
                clientTitleRussian: '',
                clientTitleUzbek: '',
                proTitleEnglish: '',
                proTitleRussian: '',
                proTitleUzbek: '',
                descriptionEnglish: '',
                descriptionRussian: '',
                descriptionUzbek: '',
            });
            setTemplateChoices(null);
            setClientChoiceType('single');
            setProChoiceType('multiple');
            setChoices([
                {
                    choiceImage: '',
                    clientEnglish: '',
                    clientRussian: '',
                    clientUzbek: '',
                    proChoices: false,
                    proEnglish: null,
                    proRussian: null,
                    proUzbek: null,
                },
            ]);

            setModalData(null);

        }
    };

    const handleUseTemplate = (state) => {
        const template = templateQuestions.find((t) => t.id === state.value);

        if (template) {
            const questionClient = template.questionText.filter((q) => q.belongs === 'client')[0];
            const questionPro = template.questionText.filter((q) => q.belongs === 'pro')[0];

            setClientChoiceType(questionClient.multiple ? 'multiple' : 'single');
            setProChoiceType(questionPro.multiple ? 'multiple' : 'single');

            setQuestion({
                filter: template.filter_show,
                proPage: template.pro_page,
                skip: template.skip,
                clientOnly: template.client_only,
                inputBar: template.input_bar,
                clientTitleId: questionClient.id,
                clientTitleEnglish: questionClient.eng,
                clientTitleRussian: questionClient.ru,
                clientTitleUzbek: questionClient.uz,
                proTitleId: questionPro.id,
                proTitleEnglish: questionPro.eng,
                proTitleRussian: questionPro.ru,
                proTitleUzbek: questionPro.uz,
            });

            const clientChoices = template.choiceText.filter((c) => c.belongs === 'client');
            clientChoices.map((choice, i) => {
                const proChoice = template.choiceText.find((ch) => ch.id === choice.proChoiceId);

                if (proChoice) {
                    const choiceObj = {
                        id: choice.id,
                        choiceImage: choice.choiceImage,
                        clientEnglish: choice.eng,
                        clientRussian: choice.ru,
                        clientUzbek: choice.uz,
                        proChoices: true,
                        proId: proChoice.id,
                        proEnglish: proChoice.eng,
                        proRussian: proChoice.ru,
                        proUzbek: proChoice.uz,
                    };

                    if (i === 0) {
                        return setChoices([choiceObj]);
                    }
                    choices.push(choiceObj);
                    return setChoices([...choices]);
                } else {
                    const choiceObj = {
                        id: choice.id,
                        choiceImage: choice.choiceImage,
                        clientEnglish: choice.eng,
                        clientRussian: choice.ru,
                        clientUzbek: choice.uz,
                        proChoices: false,
                        proEnglish: null,
                        proRussian: null,
                        proUzbek: null,
                    };

                    if (i === 0) {
                        return setChoices([choiceObj]);
                    }
                    choices.push(choiceObj);
                    return setChoices([...choices]);
                }
            });
        }
    };

    const removeProChoices = (choice, i) => {
        const newChoice = {
            ...choice,
            proChoices: false,
            proEnglish: '',
            proRussian: '',
            proUzbek: '',
        };

        choices[i] = newChoice;
        setChoices([...choices]);
    };

    const uploadChoiceImage = async (e, i) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            choices[i].choiceImage = data.image;
            setChoices([...choices]);
        }
    };

    const handleCloseModal = () => {
        toggle();

        setQuestion({
            filter: false,
            proPage: false,
            skip: false,
            clientOnly: false,
            inputBar: false,
            showImages: false,
            clientTitleEnglish: '',
            clientTitleRussian: '',
            clientTitleUzbek: '',
            proTitleEnglish: '',
            proTitleRussian: '',
            proTitleUzbek: '',
            descriptionEnglish: '',
            descriptionRussian: '',
            descriptionUzbek: '',
        });

        setTemplateChoices(null);
        setClientChoiceType('single');
        setProChoiceType('multiple');
        setChoices([
            {
                choiceImage: '',
                clientEnglish: '',
                clientRussian: '',
                clientUzbek: '',
                proChoices: false,
                proEnglish: null,
                proRussian: null,
                proUzbek: null,
            },
        ]);

        setModalAlert(null);
    };

    return (
        <Modal isOpen={isOpen} size="xl">
            <ModalHeader toggle={handleCloseModal}>Add Question</ModalHeader>
            <Form autoComplete='none' onSubmit={handleSubmit}>
                <ModalBody className="p-4">
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h6>Template</h6>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={handleUseTemplate}
                                options={templateChoices ? templateChoices : []}></Select>
                        </Col>
                        <Col md={6} className="justify-content-end">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Display at:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            autoComplete={false}
                                            
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    filter: state.target.checked,
                                                })
                                            }
                                            checked={question.filter}
                                            id="filter"
                                            type="checkbox"
                                            label="Filter"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            autoComplete={false}
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    proPage: state.target.checked,
                                                })
                                            }
                                            checked={question.proPage}
                                            id="proPage"
                                            type="checkbox"
                                            label="Pro Page"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Other:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            autoComplete={false}
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    skip: state.target.checked,
                                                })
                                            }
                                            checked={question.skip}
                                            id="skip"
                                            type="checkbox"
                                            label="Skip"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            autoComplete={false}
                                            id="clientOnly"
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    clientOnly: state.target.checked,
                                                })
                                            }
                                            checked={question.clientOnly}
                                            type="checkbox"
                                            label="Client Only"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            autoComplete={false}
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    inputBar: state.target.checked,
                                                })
                                            }
                                            checked={question.inputBar}
                                            id="inputBar"
                                            type="checkbox"
                                            label="Input bar"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {modalAlert && (
                        <>
                            <hr className="mt-4" />
                            <UncontrolledAlert color={modalAlert.color}>{modalAlert.message}</UncontrolledAlert>
                        </>
                    )}
                    <hr className="mt-4" />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Question
                            </span>
                        </h5>
                    </div>
                    <Row className="mt-3 p-2" style={{ backgroundColor: '#F9F9FA' }}>
                        <Col md={6}>
                            <h6>Client Question</h6>
                            <Input
                                name="clientTitleEnglish"
                                onChange={handleTitleChange}
                                value={question.clientTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleRussian"
                                onChange={handleTitleChange}
                                value={question.clientTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleUzbek"
                                onChange={handleTitleChange}
                                value={question.clientTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <h6>Pro Question</h6>
                            <Input
                                name="proTitleEnglish"
                                onChange={handleTitleChange}
                                value={question.proTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="proTitleRussian"
                                onChange={handleTitleChange}
                                value={question.proTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="proTitleUzbek"
                                onChange={handleTitleChange}
                                value={question.proTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Description
                            </span>
                        </h5>
                    </div>
                    <Row className="mt-3 p-2">
                        <Col md={6}>
                            <h6>Description</h6>
                            <Input
                                name="descriptionEnglish"
                                value={question.descriptionEnglish}
                                onChange={handleTitleChange}
                                placeholder="English"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionRussian"
                                value={question.descriptionRussian}
                                onChange={handleTitleChange}
                                placeholder="Russian"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionUzbek"
                                value={question.descriptionUzbek}
                                onChange={handleTitleChange}
                                placeholder="Uzbek"
                                className="mb-3"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Choices
                            </span>
                        </h5>
                    </div>
                    <Row>
                        <div className="uploader-left d-flex align-items-center justify-content-center">
                            <CustomInput
                                autoComplete={false}
                                onChange={(state) =>
                                    setQuestion({
                                        ...question,
                                        showImages: state.target.checked,
                                    })
                                }
                                checked={question.showImages}
                                id="showImages"
                                type="checkbox"
                                label="Show images"
                            />
                        </div>
                        <Col md={5}>
                            <Row className="ml-4 mt-2 mb-1">
                                <h6 className="mr-2">Client Choices</h6>
                                <ButtonGroup size="sm" className="flex width-lg">
                                    <Button
                                        type="button"
                                        onClick={() => setClientChoiceType('single')}
                                        outline={clientChoiceType !== 'single'}
                                        color="primary">
                                        Single
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setClientChoiceType('multiple')}
                                        outline={clientChoiceType !== 'multiple'}
                                        color="primary">
                                        Multiple
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <Row className="ml-4 mt-2 mb-1">
                                <h6 className="mr-2">Pro Choices</h6>
                                <ButtonGroup size="sm" className="flex width-lg">
                                    <Button
                                        type="button"
                                        onClick={() => setProChoiceType('single')}
                                        outline={proChoiceType !== 'single'}
                                        color="primary">
                                        Single
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setProChoiceType('multiple')}
                                        outline={proChoiceType !== 'multiple'}
                                        color="primary">
                                        Multiple
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                    {choices.map((choice, i) => (
                        <Row key={i} className="mt-3 p-2 align-items-start" style={{ backgroundColor: '#F9F9FA' }}>
                            <div className="uploader-left">
                                <div className="uploader-outer">
                                    <label htmlFor={'choice-image-' + i} className="uploader">
                                        {choice.choiceImage ? (
                                            <img
                                                style={{ width: 140, height: 140, borderRadius: 4, objectFit: 'cover' }}
                                                src={ASSETS_URL + choice.choiceImage}
                                                alt="choice"
                                            />
                                        ) : (
                                            <div className="inside">
                                                <Plus />
                                                <h4>Upload</h4>
                                            </div>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        id={'choice-image-' + i}
                                        onChange={(e) => uploadChoiceImage(e, i)}
                                    />
                                </div>
                                <Row noGutters>
                                    <Col md={6}>
                                        <Button
                                            type="button"
                                            className="d-relative question-index w-100 h-100 mr-1"
                                            color="soft-primary">
                                            {i + 1}
                                            <div onClick={() => removeChoice(i)} className="remove-choice">
                                                -
                                            </div>
                                        </Button>
                                    </Col>
                                    {choices.length === i + 1 && (
                                        <Col md={6}>
                                            <Button
                                                type="button"
                                                onClick={addNewChoice}
                                                className="w-100 h-100 ml-1"
                                                color="primary">
                                                <Plus />
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            <Col md={5} className="ml-4">
                                <Input
                                    name="clientEnglish"
                                    placeholder="English"
                                    className="mb-3"
                                    value={choice.clientEnglish}
                                    onChange={(e) => handleChange(e, i)}
                                    required
                                />
                                <Input
                                    name="clientRussian"
                                    placeholder="Russian"
                                    className="mb-3"
                                    value={choice.clientRussian}
                                    onChange={(e) => handleChange(e, i)}
                                    required
                                />
                                <Input
                                    name="clientUzbek"
                                    placeholder="Uzbek"
                                    className="mb-3"
                                    value={choice.clientUzbek}
                                    onChange={(e) => handleChange(e, i)}
                                    required
                                />
                            </Col>
                            <Col md={5}>
                                {choice.proChoices ? (
                                    <div className="pro-choices-inputs">
                                        <button onClick={() => removeProChoices(choice, i)} type="button">
                                            -
                                        </button>
                                        <Input
                                            name="proEnglish"
                                            placeholder="English"
                                            className="mb-3"
                                            value={choice.proEnglish}
                                            onChange={(e) => handleChange(e, i)}
                                            required
                                        />
                                        <Input
                                            name="proRussian"
                                            placeholder="Russian"
                                            className="mb-3"
                                            value={choice.proRussian}
                                            onChange={(e) => handleChange(e, i)}
                                            required
                                        />
                                        <Input
                                            name="proUzbek"
                                            placeholder="Uzbek"
                                            className="mb-3"
                                            value={choice.proUzbek}
                                            onChange={(e) => handleChange(e, i)}
                                            required
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Button
                                            type="button"
                                            className="w-100 mb-2 d-flex align-items-center justify-content-center"
                                            color="primary"
                                            onClick={() => duplicateChoice(i)}>
                                            <Layers size={20} /> <span className="ml-2">Duplicate Choice</span>
                                        </Button>
                                        <Button
                                            type="button"
                                            outline
                                            className="w-100 mt-2 d-flex align-items-center justify-content-center"
                                            color="primary"
                                            onClick={() => addProChoice(i)}>
                                            <Plus size={20} /> <span className="ml-2">Add Pro Choice</span>
                                        </Button>
                                    </>
                                )}
                            </Col>
                        </Row>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="ml-1" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Add Question
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddQuestionModal;
