export const getFileExtension = (fileName: string) => {
    const arr = fileName.split('.');
    const lastItem = arr.length - 1;
    const extension = arr[lastItem];

    return extension;
};

export const download = (url: string, name: string) => {
    const ext = getFileExtension(name);
    const element = document.createElement('a');
    const file = new Blob(
        [
            url
        ],
        { type: ext === 'pdf' ? 'application/pdf' : 'image/*' }
    );
    element.href = URL.createObjectURL(file);
    element.download = name;
    element.click();
};