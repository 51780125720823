import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from '../axios';

const EditQuestionOptions = ({
    data,
    setQuestionOptionsModal,
    setQuestionEditModal,
    setTimeQuestionEditModal,
    handleRemoveQuestion,
}) => {
    const [switchingToEdit, setSwitchingToEdit] = useState(false);
    const [removingQuestion, setRemovingQuestion] = useState(false);
    const switchToEdit = async () => {
        setSwitchingToEdit(true);
        if (data.type.includes('calendar') || data.type.includes('time')) {
            setQuestionOptionsModal(null);
            setSwitchingToEdit(false);
            return setTimeQuestionEditModal(data);
        }

        const { data: qData } = await axios.get(`/api/v2/admin/questions/${data.id}`);

        setQuestionEditModal(qData);
        setSwitchingToEdit(false);
        setQuestionOptionsModal(null);
    };

    const handleQuestionRemove = async () => {
        setRemovingQuestion(true);
        await handleRemoveQuestion(data)
        setRemovingQuestion(false);
    }

    return (
        <Modal isOpen={!!data}>
            <ModalHeader toggle={() => setQuestionOptionsModal(null)}>Edit Question</ModalHeader>
            {data && (
                <>
                    <ModalBody>
                        <h4>{data.questionText.filter((q) => q.belongs === 'client')[0].eng}</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setQuestionOptionsModal(null)} color="secondary" className="ml-1">
                            Cancel
                        </Button>
                        <Button disabled={removingQuestion} onClick={handleQuestionRemove} color="danger">
                            Remove
                        </Button>
                        <Button disabled={switchingToEdit} color="primary" onClick={switchToEdit}>
                            Edit Question
                        </Button>
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};

export default EditQuestionOptions;
