import styled from 'styled-components';

export const DocumentListContainer = styled.div`
    display: grid;
    gap: 8px;
`

export const PreviewImage = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
`;

export const PreviewRowContainer = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    gap: 10px;
    align-items: center;
`;

export const PreviewContainer = styled.div`
    display: grid;
    gap: 10px;
`;

export const UploadDocumentsContainer = styled.div`
    display: grid;
    gap: 10px
`;

export const UploadedDocumentsContainer = styled.div`
    display: grid;
    gap: 10px;
`;

export const UploadedDocumentRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 34px;
    gap: 10px;
`;