import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    CustomInput,
    Form,
    Input,
    Label,
    Row,
} from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import axios, { ASSETS_URL } from '../../axios';
import { Plus, CornerUpLeft } from 'react-feather';
import ServicesList from '../../components/ServicesList';
import { VerifyProSection } from './VerifyProSection';

const EditProPage = () => {
    const [pro, setPro] = useState(null);
    const [isRestoring, setIsRestoring] = useState(false);
    const [pros, setPros] = useState([]);
    const [services, setServices] = useState([]);
    const params = useParams();
    const history = useHistory();
    const[hide,setHide] = useState(false)
const [loading,SetLoading]= useState(true)
const[urls,setUrl] = useState()
const[proAvtar,setProfileUrl] = useState()
    useEffect(() => {
        const fetchPro = async () => {
            const prosList = await axios.get('/api/v2/admin/pro');
            setPros(prosList.data);

            const services = await axios.get('/api/v1/admin/service');
            const options = services.data.map((service) => {
                return {
                    value: service.id,
                    label: service.titleEN,
                };
            });

            setServices(options);

            const { data } = await axios.get(`/api/v2/admin/pro/${params.id}`);

            if (data) {
                setHide(data.hide_pro)
                setPro(data);
            }
        };
SetLoading(false)
        fetchPro();
    }, []);
   
    const handleChange = (e) => {
        setPro({
            ...pro,
            [e.target.name]: e.target.value,
        });
    };

    const uploadAvatar = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);
        setProfileUrl( window.URL.createObjectURL(e.target.files[0]))
        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            setPro({
                ...pro,
                profile_image: data.image,
            });
        }
    };
    const  hidePro =()=>{
        console.log("heu")
        setHide(!hide)
        setPro({ ...pro, hide_pro: !hide })
    }
    const uploadImage = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);
        setUrl( window.URL.createObjectURL(e.target.files[0]))
       
        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            let  work_images = pro.work_images;

            if (work_images) {
                work_images.unshift(data.image);
            } else {
                work_images = [data.image];
            }

            setPro({
                ...pro,
                work_images,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(pro?.user?.phone?.length===9 && pro?.overview_phone?.length===9){

     

   await axios.put('/api/v2/admin/pro', pro);
        
    


        const idx = pros.findIndex((p) => p.id === pro.id);

        if (idx > -1) {
            pros[idx] = pro;
            setPros([...pros]);
        }

        history.go(0);
    };
    }
    const removeProfileImage = (path) => {

        axios.get(`api/upload/delete-image?path=${path}`).then((res)=>{
      
        })
        setPro({
            ...pro,
            profile_image: null,
        });
    };

    const removeWorkImage = (i,path) => {
        axios.get(`api/upload/delete-image?path=${path}`).then((res)=>{
            console.log(res);
        })
        const work_images = pro.work_images.filter((_, idx) => idx !== i);

        setPro({
            ...pro,
            work_images,
        });
    };
    const handleRestore = async () => {
        if(pro){
            setIsRestoring(true);
            await axios.post(`/api/v1/admin/pro/restore/${pro.id}`)
            const { data } = await axios.get(`/api/v2/admin/pro/${params.id}`);

            if (data) {
                setPro(data);
            }
            setIsRestoring(false);
        }
    }

    return (

        <div>
            {!pro?<div style={{position:"absolute",left:"56%",top:"50%",transform:"translate(-50%, -50%)"}}><lottie-player src="loader.json"  background="transparent"  speed="1"  style={{width:"150px",height:"150px"}} loop  autoplay></lottie-player>
            <p style={{textAlign:"center",marginTop:"1rem",fontWeight:"bold", color:"purple"}}>Please Wait ...</p> </div>:
            <>
            <Row className="page-title">
                <Col className="col-12">
                    <PageTitle
                        breadCrumbItems={[
                            { label: 'Pros', path: '/pros' },
                            { label: 'Pro Details', path: '/pros/:id', active: true },
                        ]}
                        title={'Pro Details'}
                    />
                </Col>
            </Row>
            <Card>
                {pro && (
                    <>
                        {pro.deletedAt
                            ? (
                                <Button
                                    disabled={isRestoring}
                                    title={'Restore'}
                                    type='button'
                                    color='success'
                                    onClick={handleRestore}
                                >
                                    <span style={{ marginRight: 8 }}>Restore</span>
                                    <CornerUpLeft />
                                </Button>
                            )
                            : null 
                        }
                        <Form action="POST" onSubmit={handleSubmit}>
                            <CardBody className="p-4">
                                <Row noGutters>
                                    <Col md={4} className="pr-2 pl-2">
                                        <h6>Avatar</h6>
                                        <div className="uploader-outer">
                                            <label htmlFor="choice-image" className="uploader">
                                                <div className="inside">
                                                    <Plus />
                                                    <h4>Upload</h4>
                                                </div>
                                            </label>
                                            <Input autocomplete="off"type="file" id="choice-image" onChange={uploadAvatar} />
                                        </div>
                                        {pro.profile_image && (
                                            <div className="profile_image_preview">
                                                <img
                                                    src={proAvtar? proAvtar: ASSETS_URL + pro.profile_image}
                                                    style={{ objectFit: 'cover', width: 200, height: 200 }}
                                                    alt="profile"
                                                />
                                                <button type="button" onClick={()=>{removeProfileImage( pro?.profile_image)}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24">
                                                        <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        )}
                                        <div className="mt-4">
                                            <h6> Phone Confirmed</h6>
                                            <CustomInput
                                                checked={pro?.user?.phone_validated}
                                                // onChange={() => setPro({ ...pro, overview_confirmed: true })}
                                                type="radio"
                                                name="confirmed"
                                                id="confirmedYes"
                                                label="Yes"
                                                inline
                                                readOnly 
                                            />
                                            <CustomInput
                                                checked={!pro?.user?.phone_validated}
                                                // onChange={() => setPro({ ...pro, overview_confirmed: false })}
                                                type="radio"
                                                name="confirmed"
                                                id="confirmedNo"
                                                label="No"
                                                inline
                                            />
                                        </div>
                                        
                                        <div className="mt-3">
                                            <h6>Payments</h6>
                                            <Row>
                                                <Col md={6}>
                                                    <CustomInput
                                                        checked={pro.payments ? pro.payments.includes('cash') : false}
                                                        onChange={(state) => {
                                                            if (state.target.checked) {
                                                                if (pro.payments) {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: [...pro.payments, 'cash'],
                                                                    });
                                                                } else {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: ['cash'],
                                                                    });
                                                                }
                                                            } else {
                                                                setPro({
                                                                    ...pro,
                                                                    payments: pro.payments.filter((p) => p !== 'cash'),
                                                                });
                                                            }
                                                        }}
                                                        // className="mb-2"
                                                        type="switch"
                                                        id="cash"
                                                        label="Cash"
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput
                                                        checked={pro.payments ? pro.payments.includes('uzcard') : false}
                                                        onChange={(state) => {
                                                            if (state.target.checked) {
                                                                if (pro.payments) {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: [...pro.payments, 'uzcard'],
                                                                    });
                                                                } else {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: ['uzcard'],
                                                                    });
                                                                }
                                                            } else {
                                                                setPro({
                                                                    ...pro,
                                                                    payments: pro.payments.filter(
                                                                        (p) => p !== 'uzcard'
                                                                    ),
                                                                });
                                                            }
                                                        }}
                                                        className="mb-2"
                                                        type="switch"
                                                        id="uzcard"
                                                        label="UzCard"
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput
                                                        checked={pro.payments ? pro.payments.includes('humo') : false}
                                                        onChange={(state) => {
                                                            if (state.target.checked) {
                                                                if (pro.payments) {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: [...pro.payments, 'humo'],
                                                                    });
                                                                } else {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: ['humo'],
                                                                    });
                                                                }
                                                            } else {
                                                                setPro({
                                                                    ...pro,
                                                                    payments: pro.payments.filter((p) => p !== 'humo'),
                                                                });
                                                            }
                                                        }}
                                                        className="mb-2"
                                                        type="switch"
                                                        id="humo"
                                                        label="Humo"
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput
                                                        checked={pro.payments ? pro.payments.includes('payme') : false}
                                                        onChange={(state) => {
                                                            if (state.target.checked) {
                                                                if (pro.payments) {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: [...pro.payments, 'payme'],
                                                                    });
                                                                } else {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: ['payme'],
                                                                    });
                                                                }
                                                            } else {
                                                                setPro({
                                                                    ...pro,
                                                                    payments: pro.payments.filter((p) => p !== 'payme'),
                                                                });
                                                            }
                                                        }}
                                                        className="mb-2"
                                                        type="switch"
                                                        id="payme"
                                                        label="Payme"
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput
                                                        checked={pro.payments ? pro.payments.includes('click') : false}
                                                        onChange={(state) => {
                                                            if (state.target.checked) {
                                                                if (pro.payments) {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: [...pro.payments, 'click'],
                                                                    });
                                                                } else {
                                                                    setPro({
                                                                        ...pro,
                                                                        payments: ['click'],
                                                                    });
                                                                }
                                                            } else {
                                                                setPro({
                                                                    ...pro,
                                                                    payments: pro.payments.filter((p) => p !== 'click'),
                                                                });
                                                            }
                                                        }}
                                                        className="mb-2"
                                                        type="switch"
                                                        id="click"
                                                        label="Click"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-4">
                                            <h6> Documents verified</h6>
                                            <CustomInput
                                                checked={pro?.is_verified}
                                                onChange={() => setPro({ ...pro, is_verified: true })}
                                                type="radio"
                                                name="verified"
                                                id="verifiedYes"
                                                label="Yes"
                                                inline
                                                readOnly 
                                            />
                                            <CustomInput
                                                checked={!pro?.is_verified}
                                                onChange={() => setPro({ ...pro, is_verified: false })}
                                                type="radio"
                                                name="verified"
                                                id="verifiedNo"
                                                label="No"
                                                inline
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <VerifyProSection />
                                        </div>
                                        {/* <div className="mt-3">
                                            <h6 >Hide Account</h6>
                                            <CustomInput
                                                style={{ marginLeft: "15px" ,zIndex:"67"}}
                                                checked={hide}
                                                onChange={hidePro}
                                                size="sm"
                                                type="switch"
                                                name="hidePro"
                                                label="Hide Account"
                                                inline
                                            />
                                        </div> */}
                                    </Col>
                                    <Col md={8} className="pl-4 pr-2">
                                        <Row noGutters>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="name">Name</Label>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    placeholder="Name"
                                                    value={pro.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="email">Email</Label>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={pro.user ? (pro.user.email ? pro.user.email : '') : ''}
                                                    onChange={(e) =>
                                                        setPro({
                                                            ...pro,
                                                            user: {
                                                                ...pro.user,
                                                                email: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="hired">Hired</Label>
                                                <Input
                                                    type="number"
                                                    name="overview_hired"
                                                    id="hired"
                                                    placeholder="Hired"
                                                    value={pro.overview_hired}
                                                    onChange={handleChange}
                                                />
                                            </Col>

                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="employees">Employees Count</Label>
                                                <Input
                                                    type="number"
                                                    name="overview_employees_count"
                                                    id="employees"
                                                    placeholder="Employees Count"
                                                    value={pro.overview_employees_count}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="yearFounded">Year Founded</Label>
                                                <Input
                                                    type="number"
                                                    name="overview_year_founded"
                                                    id="yearFounded"
                                                    placeholder="Year Founded"
                                                    value={pro.overview_year_founded}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="phone">Phone</Label>
                                              
                                                <Input
                                                    name="overview_phone"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    value={pro.user ? (pro?.user?.phone ? pro?.user?.phone : '') : ''}
                                                    onChange={(e) =>
                                                        setPro({
                                                            ...pro,
                                                            user: {
                                                                ...pro.user,
                                                                phone: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    required
                                              
                                                  />
                                                    {pro?.user?.phone?.length<9?<span style={{color:"red"}}>Phone Number must be of  9 digit</span>:pro?.user?.phone?.length>9?<span  style={{color:"red"}}>Phone Number must be of  9 digit</span>:null}
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="phone">Business Phone</Label>
                                                <Input
                                                    name="overview_phone"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    value={pro.overview_phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                   {pro?.overview_phone?.length<9?<span style={{color:"red"}}>Phone Number must be of  9 digit</span>:pro?.overview_phone?.length>9?<span  style={{color:"red"}}>Phone Number must be of  9 digit</span>:null}
                                            </Col>
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="website">Website</Label>
                                                <Input
                                                    type="url"
                                                    name="overview_website"
                                                    id="website"
                                                    placeholder="Website"
                                                    value={pro.overview_website}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            {pro.overview_address && pro.overview_address.length > 0 && (
                                                <Col md={6} className="pr-4 mb-4">
                                                    <Label for="address">Address</Label>
                                                    {pro.overview_address.map((address, i) => {
                                                        const handleAddress = (e, i) => {
                                                            const address = pro.overview_address;
                                                            address[i] = e.target.value;

                                                            setPro({ ...pro, overview_address: address });
                                                        };
                                                        return (
                                                            <Input
                                                                key={i}
                                                                name="address"
                                                                // id="address"
                                                                placeholder="Address"
                                                                value={address}
                                                                className="mb-2"
                                                                onChange={(e) => handleAddress(e, i)}
                                                            />
                                                        );
                                                    })}
                                                </Col>
                                            )}
                                            <Col md={6} className="pr-4 mb-2">
                                                <Label for="coins">Coins</Label>
                                                <Input
                                                    type="number"
                                                    name="coins"
                                                    id="coins"
                                                    placeholder="coins"
                                                    value={pro.coin ? pro.coin.count : 0}
                                                    onChange={(e) =>
                                                        setPro({
                                                            ...pro,
                                                            coin: {
                                                                ...pro.coin,
                                                                count: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col md={6} className="pr-4 mb-2">
                                                <Label for="description">Description</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    id="description"
                                                    placeholder="Description"
                                                    value={pro.description}
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col md={6} />
                                            <h6>Photos</h6>
                                            <Col md={12} className="pr-1">
                                                <div className="uploader-outer">
                                                    <label htmlFor="pro-images-uploader" className="uploader">
                                                        <div className="inside">
                                                            <Plus />
                                                            <h4>Upload</h4>
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        onChange={uploadImage}
                                                        id="pro-images-uploader"
                                                    />
                                                    <Row>
                                                        {pro.work_images &&
                                                            pro.work_images.map((image, i) => (
                                                                <Col key={i} md={2}>
                                                                    <div className="work_images_preview">
                                                                        <img
                                                                            src={ urls && i ===0?urls: ASSETS_URL + image}
                                                                            className="w-100 mb-2"
                                                                            style={{ height: 90, objectFit: 'cover' }}
                                                                            alt="service"
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeWorkImage(i,image)}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24">
                                                                                <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} />
                                    <Col md={8}>
                                        <ServicesList id={pro.id} servicesList={services} services={pro.services} />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row className="justify-content-end">
                                    <Button color="primary" type="submit">
                                        Save
                                    </Button>
                                </Row>
                            </CardFooter>
                        </Form>
                    </>
                )}
            </Card>
            </>
}
        </div>
                                                            
    );
};

export default EditProPage;
