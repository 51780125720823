// @flow
import { NOTIFICATIONS_SEEN, NOTIFICATIONS_SUCCESS } from './constants';

const initialState = {
    new: false,
    data: [],
};

const AppMenu = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_SUCCESS:
            const isNew = action.payload.length > 0 && action.payload.findIndex((n) => n.seen === false);
            if (isNew > -1) {
                return {
                    new: true,
                    data: action.payload,
                };
            }

            return {
                ...state,
                data: action.payload,
            };

        case NOTIFICATIONS_SEEN:
            return {
                ...state,
                new: false,
            };

        default:
            return state;
    }
};

export default AppMenu;
