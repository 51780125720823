/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from '../../../axios';
import { VerificationDocument } from '../../../types';
import { DocumentListContainer, UploadedDocumentRow, UploadedDocumentsContainer } from './style';
import DocumentsUpload from './DocumentsUpload';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MinusCircle } from 'react-feather';

const getFileExtension = (fileName: string) => {
    const arr = fileName.split('.');
    const lastItem = arr.length - 1;
    const extension = arr[lastItem];

    return extension;
};

const download = (url: string, name: string) => {
    const ext = getFileExtension(name);
    const element = document.createElement('a');
    const type= ext === 'pdf' ? 'application/pdf' : 'image/*';
    const file = new Blob([url], { type });

    element.href = URL.createObjectURL(file);
    element.download = name;
    element.click();
};


const VerifyProSection = () => {
    const [documents, setDocuments] = useState<VerificationDocument[]>([])
    const { id: proId } = useParams<{ id: string }>();

    const handleFileDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string, name: string) => {
        e.preventDefault();
        download(url, name);
    }

    const handleFileDelete = async (documentId: number) => {
        await axios.delete(`/api/v2/admin/pro/verification-documents/${documentId}`)
        const newDocuments = documents.filter(doc => doc.id !== documentId);
        setDocuments(newDocuments);
    }

    const addNewDocuments = (docs: VerificationDocument[]) => {
        setDocuments([...documents, ...docs]);
    }

    useEffect(() => {
        const getDocuments = async () => {
            const { data: verificationDocuments } = await axios.get<VerificationDocument[]>(`/api/v3/verification-documents/${proId}`)
            setDocuments(verificationDocuments)
        }
        getDocuments()
    }, [proId])

    return (
        <DocumentListContainer>
            <h4>Verification documents</h4>
            <UploadedDocumentsContainer>
                {documents.map(({ id, name, url }, index) => (
                    <UploadedDocumentRow>
                        <a
                            style={{ display: 'block' }}
                            href={url}
                            onClick={(e) => handleFileDownload(e, url, name)} key={id}
                        >
                            {index + 1}{'. '}{name}
                        </a>
                        <UncontrolledDropdown size="sm" className="d-inline">
                        <DropdownToggle color="danger">
                            <MinusCircle size={16} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem className="text-danger">
                                <span onClick={() => handleFileDelete(id)} className="mr-2 p-1">
                                    Confirm Delete
                                </span>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Cancel</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    </UploadedDocumentRow>
                ))}
            </UploadedDocumentsContainer>
            <DocumentsUpload setDocuments={addNewDocuments} />
        </DocumentListContainer>
    )
}

export default VerifyProSection
