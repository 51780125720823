import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    CustomInput,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Select from 'react-select';
import { Calendar, Clock } from 'react-feather';
import axios from '../axios';

const AddCalendarQuestionModal = ({
    calendarQuestionModal,
    setCalendarQuestionModal,
    timeQuestions,
    setTimeQuestions,
    activeService,
    setAlert,
    templateQuestions,
}) => {
    const [question, setQuestion] = useState({
        filter: false,
        proPage: false,
        skip: false,
        clientOnly: false,
        inputBar: false,
        questionType: 'calendar',
        clientTitleEnglish: '',
        clientTitleRussian: '',
        clientTitleUzbek: '',
        proTitleEnglish: '',
        proTitleRussian: '',
        proTitleUzbek: '',
        descriptionEnglish: '',
        descriptionRussian: '',
        descriptionUzbek: '',
    });

    const [choicesType, setChoicesType] = useState('single');

    const [templateChoices, setTemplateChoices] = useState(null);

    useEffect(() => {
        if (templateQuestions) {
            const options = templateQuestions.map((temp) => {
                return {
                    value: temp.id,
                    label: temp.questionText.filter((q) => q.belongs === 'client')[0].eng,
                };
            });

            setTemplateChoices(options);
        }
    }, [templateQuestions]);

    const toggle = () => {
        setCalendarQuestionModal((prevState) => !prevState);

        setQuestion({
            filter: false,
            proPage: false,
            skip: false,
            clientOnly: false,
            inputBar: false,
            questionType: 'calendar',
            clientTitleEnglish: '',
            clientTitleRussian: '',
            clientTitleUzbek: '',
            proTitleEnglish: '',
            proTitleRussian: '',
            proTitleUzbek: '',
            descriptionEnglish: '',
            descriptionRussian: '',
            descriptionUzbek: '',
        });

        setChoicesType('single');

        setTemplateChoices(null);
    };

    const handleTitleChange = (e) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const type =
            question.questionType === 'time'
                ? choicesType === 'multiple'
                    ? 'time-multiple'
                    : choicesType === 'range'
                    ? 'time-range'
                    : 'time'
                : choicesType === 'multiple'
                ? 'calendar-multiple'
                : choicesType === 'range'
                ? 'calendar-range'
                : 'calendar';

        const body = {
            questionType: question.questionType,
            options: {
                type,
                skip: question.skip,
                filter: question.filter,
                proPage: question.proPage,
                clientOnly: question.clientOnly,
                inputBar: question.inputBar,
            },
            title: {
                clientEnglish: question.clientTitleEnglish,
                clientRussian: question.clientTitleRussian,
                clientUzbek: question.clientTitleUzbek,
                proEnglish: question.proTitleEnglish,
                proRussian: question.proTitleRussian,
                proUzbek: question.proTitleUzbek,
            },
            description: {
                english: question.descriptionEnglish,
                russian: question.descriptionRussian,
                uzbek: question.descriptionUzbek,
            },
            serviceId: activeService,
        };

        const res = await axios.post('/api/v2/admin/questions/time', body);

        if (res.status === 201) {
            timeQuestions.unshift(res.data.question);

            setTimeQuestions([...timeQuestions]);

            setCalendarQuestionModal(false);
            setQuestion({
                filter: false,
                proPage: false,
                skip: false,
                clientOnly: false,
                inputBar: false,
                questionType: 'calendar',
                clientTitleEnglish: '',
                clientTitleRussian: '',
                clientTitleUzbek: '',
                proTitleEnglish: '',
                proTitleRussian: '',
                proTitleUzbek: '',
                descriptionEnglish: '',
                descriptionRussian: '',
                descriptionUzbek: '',
            });

            setAlert({
                color: 'success',
                message: 'question successfully added',
            });
        }
    };

    const handleUseTemplate = (state) => {
        const template = templateQuestions.find((t) => t.id === state.value);

        if (template) {
            const questionClient = template.questionText.filter((q) => q.belongs === 'client')[0];
            const questionPro = template.questionText.filter((q) => q.belongs === 'pro')[0];

            setQuestion({
                questionType: 'calendar',
                filter: template.filter_show,
                proPage: template.pro_page,
                skip: template.skip,
                clientOnly: template.client_only,
                inputBar: template.input_bar,
                clientTitleId: questionClient.id,
                clientTitleEnglish: questionClient.eng,
                clientTitleRussian: questionClient.ru,
                clientTitleUzbek: questionClient.uz,
                proTitleId: questionPro.id,
                proTitleEnglish: questionPro.eng,
                proTitleRussian: questionPro.ru,
                proTitleUzbek: questionPro.uz,
            });
        }
    };

    return (
        <Modal isOpen={calendarQuestionModal} size="xl">
            <ModalHeader toggle={toggle}>Add Calendar / Time Question</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h6>Template</h6>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={handleUseTemplate}
                                options={templateChoices ? templateChoices : []}></Select>
                        </Col>
                        <Col md={6} className="justify-content-end">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Display at:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    filter: state.target.checked,
                                                })
                                            }
                                            checked={question.filter}
                                            id="filter"
                                            type="checkbox"
                                            label="Filter"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    proPage: state.target.checked,
                                                })
                                            }
                                            checked={question.proPage}
                                            id="proPage"
                                            type="checkbox"
                                            label="Pro Page"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="text-right">Other:</h6>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <CustomInput
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    skip: state.target.checked,
                                                })
                                            }
                                            checked={question.skip}
                                            id="skip"
                                            type="checkbox"
                                            label="Skip"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            id="clientOnly"
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    clientOnly: state.target.checked,
                                                })
                                            }
                                            checked={question.clientOnly}
                                            type="checkbox"
                                            label="Client Only"
                                            className="mr-3"
                                        />
                                        <CustomInput
                                            onChange={(state) =>
                                                setQuestion({
                                                    ...question,
                                                    inputBar: state.target.checked,
                                                })
                                            }
                                            checked={question.inputBar}
                                            id="inputBar"
                                            type="checkbox"
                                            label="Input bar"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="mt-4" />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Calendar or Time
                            </span>
                        </h5>
                    </div>
                    <Row className="ml-2 mb-3 flex">
                        <ButtonGroup className="flex width-lg">
                            <Button
                                type="button"
                                onClick={() =>
                                    setQuestion({
                                        ...question,
                                        questionType: 'calendar',
                                    })
                                }
                                outline={question.questionType === 'time'}
                                className="width-lg d-flex justify-content-center align-items-center"
                                color="primary">
                                <Calendar size="16" /> <span className="ml-1">Calendar</span>
                            </Button>
                            <Button
                                type="button"
                                onClick={() =>
                                    setQuestion({
                                        ...question,
                                        questionType: 'time',
                                    })
                                }
                                outline={question.questionType === 'calendar'}
                                className="width-lg d-flex justify-content-center align-items-center"
                                color="primary">
                                <Clock size="16" /> <span className="ml-1">Time</span>
                            </Button>
                        </ButtonGroup>
                    </Row>
                    <hr className="mt-4" />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Question
                            </span>
                        </h5>
                    </div>
                    <Row className="ml-2 mt-2 mb-1">
                        <h6 className="mr-2">Choices Type</h6>
                        <ButtonGroup size="sm" className="flex width-lg">
                            <Button
                                type="button"
                                onClick={() => setChoicesType('single')}
                                outline={choicesType !== 'single'}
                                color="primary">
                                Single
                            </Button>
                            <Button
                                type="button"
                                onClick={() => setChoicesType('multiple')}
                                outline={choicesType !== 'multiple'}
                                color="primary">
                                Multiple
                            </Button>
                            <Button
                                type="button"
                                onClick={() => setChoicesType('range')}
                                outline={choicesType !== 'range'}
                                color="primary">
                                Range
                            </Button>
                        </ButtonGroup>
                    </Row>
                    <Row className="mt-3 p-2" style={{ backgroundColor: '#F9F9FA' }}>
                        <Col md={6}>
                            <h6>Client Question</h6>
                            <Input
                                name="clientTitleEnglish"
                                onChange={handleTitleChange}
                                value={question.clientTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleRussian"
                                onChange={handleTitleChange}
                                value={question.clientTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="clientTitleUzbek"
                                onChange={handleTitleChange}
                                value={question.clientTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <h6>Pro Question</h6>
                            <Input
                                name="proTitleEnglish"
                                onChange={handleTitleChange}
                                value={question.proTitleEnglish}
                                placeholder="English"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="proTitleRussian"
                                onChange={handleTitleChange}
                                value={question.proTitleRussian}
                                placeholder="Russian"
                                className="mb-3"
                                required
                            />
                            <Input
                                name="proTitleUzbek"
                                onChange={handleTitleChange}
                                value={question.proTitleUzbek}
                                placeholder="Uzbek"
                                className="mb-3"
                                required
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <h5 style={{ marginTop: -25 }}>
                            <span className="p-2" style={{ backgroundColor: 'white' }}>
                                Description
                            </span>
                        </h5>
                    </div>
                    <Row className="mt-3 p-2">
                        <Col md={6}>
                            <h6>Description</h6>
                            <Input
                                name="descriptionEnglish"
                                value={question.descriptionEnglish}
                                onChange={handleTitleChange}
                                placeholder="English"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionRussian"
                                value={question.descriptionRussian}
                                onChange={handleTitleChange}
                                placeholder="Russian"
                                className="mb-3"
                            />
                            <Input
                                name="descriptionUzbek"
                                value={question.descriptionUzbek}
                                onChange={handleTitleChange}
                                placeholder="Uzbek"
                                className="mb-3"
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={toggle} color="secondary" className="ml-1">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Add Question
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddCalendarQuestionModal;
