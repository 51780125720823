import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { editQuestionModalReducerKey, INIT_STATE as editQuestionModalState } from '../components/EditQuestionModal';
import reducers from './reducers';
import sagas from './sagas';

const INITIAL_STATE = {
    [editQuestionModalReducerKey]: editQuestionModalState
}

/* This should be considered a hack unless the state is typed properly */
type TempState = typeof INITIAL_STATE;

const typedReducers = reducers as unknown as TempState;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// @ts-ignore
const store = createStore(typedReducers, INITIAL_STATE, composeEnhancers(applyMiddleware(...middlewares)));
export function configureStore() {
    sagaMiddleware.run(sagas as any);
    return store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch