import { id } from 'date-fns/locale';
import React from 'react';



import ChatList from './chatlist';

const Chat = (chats) => {
   
   
console.log(chats.chats)
    const chatMessages = [
        { id: 1, userName: 'Geneva', text: 'Hello!' },
        {
            id: 2,
            userName: 'Shreyu',
            text: 'Hi, How are you? What about our next meeting?',
                }
    ];

    return (
        <ChatList messages={chats.chats&&chats.chats}></ChatList>
    );
};

export default Chat;
