import React from 'react';
import { Plus } from 'react-feather';
import { useHistory } from 'react-router';
import {
    Button,
    Col,
    CustomInput,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledAlert,
} from 'reactstrap';
import axios, { ASSETS_URL } from '../axios';
import ServicesList from './ServicesList';

const EditProModal = ({ services, editProData, setEditProData, alert, pros, setPros }) => {
    const history = useHistory();

    const toggle = () => {
        setEditProData(null);
    };

    const handleChange = (e) => {
        setEditProData({
            ...editProData,
            [e.target.name]: e.target.value,
        });
    };

    const uploadAvatar = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            setEditProData({
                ...editProData,
                profile_image: data.image,
            });
        }
    };

    const uploadImage = async (e) => {
        const body = new FormData();

        body.append('image', e.target.files[0]);

        const { data } = await axios.post('/api/upload/image', body);

        if (data.image) {
            const work_images = editProData.work_images;

            if (work_images) {
                work_images.unshift(data.image);
            } else {
                work_images = [data.image];
            }

            setEditProData({
                ...editProData,
                work_images,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios.put('/api/v2/admin/pro', editProData);

        const idx = pros.findIndex((p) => p.id ===editProData.id);

        if (idx > -1) {
            pros[idx] = editProData;
            setPros([...pros]);
        }

        history.go(0);
    };

    const removeProfileImage = () => {
        setEditProData({
            ...editProData,
            profile_image: null,
        });
    };

    const removeWorkImage = (i) => {
        const work_images = editProData.work_images.filter((_, idx) => idx !== i);

        setEditProData({
            ...editProData,
            work_images,
        });
    };

    return (
        <Modal isOpen={!!editProData} size="xl">
            <ModalHeader toggle={toggle}>Edit Professional</ModalHeader>
            <Form action="POST" onSubmit={handleSubmit}>
                <ModalBody className="p-4">
                    {alert && <UncontrolledAlert color={alert.color}>{alert.message}</UncontrolledAlert>}
                    {editProData && (
                        <>
                            <Row noGutters>
                                <Col md={4} className="pr-2 pl-2">
                                    <h6>Avatar</h6>
                                    <div className="uploader-outer">
                                        <label htmlFor="choice-image" className="uploader">
                                            <div className="inside">
                                                <Plus />
                                                <h4>Upload</h4>
                                            </div>
                                        </label>
                                        <Input autocomplete="off"type="file" id="choice-image" onChange={uploadAvatar} />
                                    </div>
                                    {editProData.profile_image && (
                                        <div className="profile_image_preview">
                                            <img
                                                src={ASSETS_URL + editProData.profile_image}
                                                style={{ objectFit: 'cover', width: 200, height: 200 }}
                                                alt="profile"
                                            />
                                            <button type="button" onClick={removeProfileImage}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24">
                                                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <h6>Confirmed</h6>
                                        <CustomInput
                                            checked={editProData.overview_confirmed}
                                            onChange={() =>
                                                setEditProData({ ...editProData, overview_confirmed: true })
                                            }
                                            type="radio"
                                            name="confirmed"
                                            id="confirmedYes"
                                            label="Yes"
                                            inline
                                        />
                                        <CustomInput
                                            checked={!editProData.overview_confirmed}
                                            onChange={() =>
                                                setEditProData({ ...editProData, overview_confirmed: false })
                                            }
                                            type="radio"
                                            name="confirmed"
                                            id="confirmedNo"
                                            label="No"
                                            inline
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <h6>Payments</h6>
                                        <Row>
                                            <Col md={6}>
                                                <CustomInput
                                                    checked={
                                                        editProData.payments
                                                            ? editProData.payments.includes('cash')
                                                            : false
                                                    }
                                                    onChange={(state) => {
                                                        if (state.target.checked) {
                                                            if (editProData.payments) {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: [...editProData.payments, 'cash'],
                                                                });
                                                            } else {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: ['cash'],
                                                                });
                                                            }
                                                        } else {
                                                            setEditProData({
                                                                ...editProData,
                                                                payments: editProData.payments.filter(
                                                                    (p) => p !== 'cash'
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                    // className="mb-2"
                                                    type="switch"
                                                    id="cash"
                                                    label="Cash"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <CustomInput
                                                    checked={
                                                        editProData.payments
                                                            ? editProData.payments.includes('uzcard')
                                                            : false
                                                    }
                                                    onChange={(state) => {
                                                        if (state.target.checked) {
                                                            if (editProData.payments) {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: [...editProData.payments, 'uzcard'],
                                                                });
                                                            } else {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: ['uzcard'],
                                                                });
                                                            }
                                                        } else {
                                                            setEditProData({
                                                                ...editProData,
                                                                payments: editProData.payments.filter(
                                                                    (p) => p !== 'uzcard'
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                    className="mb-2"
                                                    type="switch"
                                                    id="uzcard"
                                                    label="UzCard"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <CustomInput
                                                    checked={
                                                        editProData.payments
                                                            ? editProData.payments.includes('humo')
                                                            : false
                                                    }
                                                    onChange={(state) => {
                                                        if (state.target.checked) {
                                                            if (editProData.payments) {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: [...editProData.payments, 'humo'],
                                                                });
                                                            } else {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: ['humo'],
                                                                });
                                                            }
                                                        } else {
                                                            setEditProData({
                                                                ...editProData,
                                                                payments: editProData.payments.filter(
                                                                    (p) => p !== 'humo'
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                    className="mb-2"
                                                    type="switch"
                                                    id="humo"
                                                    label="Humo"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <CustomInput
                                                    checked={
                                                        editProData.payments
                                                            ? editProData.payments.includes('payme')
                                                            : false
                                                    }
                                                    onChange={(state) => {
                                                        if (state.target.checked) {
                                                            if (editProData.payments) {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: [...editProData.payments, 'payme'],
                                                                });
                                                            } else {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: ['payme'],
                                                                });
                                                            }
                                                        } else {
                                                            setEditProData({
                                                                ...editProData,
                                                                payments: editProData.payments.filter(
                                                                    (p) => p !== 'payme'
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                    className="mb-2"
                                                    type="switch"
                                                    id="payme"
                                                    label="Payme"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <CustomInput
                                                    checked={
                                                        editProData.payments
                                                            ? editProData.payments.includes('click')
                                                            : false
                                                    }
                                                    onChange={(state) => {
                                                        if (state.target.checked) {
                                                            if (editProData.payments) {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: [...editProData.payments, 'click'],
                                                                });
                                                            } else {
                                                                setEditProData({
                                                                    ...editProData,
                                                                    payments: ['click'],
                                                                });
                                                            }
                                                        } else {
                                                            setEditProData({
                                                                ...editProData,
                                                                payments: editProData.payments.filter(
                                                                    (p) => p !== 'click'
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                    className="mb-2"
                                                    type="switch"
                                                    id="click"
                                                    label="Click"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    
                                </Col>
                                <Col md={8} className="pl-4 pr-2">
                                    <Row noGutters>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="name">Name</Label>
                                            <Input
                                                name="name"
                                                id="name"
                                                placeholder="Name"
                                                value={editProData.name}
                                                onChange={handleChange}
                                                autocomplete="new-password"
                                                required
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                autocomplete="new-password"
                                                value={
                                                    editProData.user
                                                        ? editProData.user.email
                                                            ? editProData.user.email
                                                            : ''
                                                        : ''
                                                }
                                                onChange={(e) =>
                                                    setEditProData({
                                                        ...editProData,
                                                        user: {
                                                            ...editProData.user,
                                                            email: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="hired">Hired</Label>
                                            <Input
                                                type="number"
                                                name="overview_hired"
                                                id="hired"
                                                placeholder="Hired"
                                                autocomplete="new-password"
                                                value={editProData.overview_hired}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="employees">Employees Count</Label>
                                            <Input
                                                type="number"
                                                name="overview_employees_count"
                                                id="employees"
                                                placeholder="Employees Count"
                                                autocomplete="new-password"
                                                value={editProData.overview_employees_count}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="yearFounded">Year Founded</Label>
                                            <Input
                                                type="number"
                                                name="overview_year_founded"
                                                id="yearFounded"
                                                placeholder="Year Founded"
                                                autocomplete="new-password"
                                                value={editProData.overview_year_founded}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="phone">Phone</Label>
                                            <Input
                                                name="overview_phone"
                                                id="phone"
                                                placeholder="Phone"
                                                autocomplete="new-password"
                                                value={editProData.overview_phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-4">
                                            <Label for="website">Website</Label>
                                            <Input
                                                type="url"
                                                name="overview_website"
                                                id="website"
                                                placeholder="Website"
                                                autocomplete="new-password"
                                                value={editProData.overview_website}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        {editProData.overview_address && editProData.overview_address.length > 0 && (
                                            <Col md={6} className="pr-4 mb-4">
                                                <Label for="address">Address</Label>
                                                {editProData.overview_address.map((address, i) => {
                                                    const handleAddress = (e, i) => {
                                                        const address = editProData.overview_address;
                                                        address[i] = e.target.value;

                                                        setEditProData({ ...editProData, overview_address: address });
                                                    };
                                                    return (
                                                        <Input
                                                            key={i}
                                                            name="address"
                                                            // id="address"
                                                            placeholder="Address"
                                                            value={address}
                                                            autocomplete="new-password"
                                                            className="mb-2"
                                                            onChange={(e) => handleAddress(e, i)}
                                                        />
                                                    );
                                                })}
                                            </Col>
                                        )}
                                        <Col md={6} className="pr-4 mb-2">
                                            <Label for="coins">Coins</Label>
                                            <Input
                                                type="number"
                                                name="coins"
                                                id="coins"
                                                placeholder="coins"
                                                autocomplete="new-password"
                                                value={editProData.coin ? editProData.coin.count : 0}
                                                onChange={(e) =>
                                                    setEditProData({
                                                        ...editProData,
                                                        coin: {
                                                            ...editProData.coin,
                                                            count: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col md={6} className="pr-4 mb-2">
                                            <Label for="description">Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Description"
                                                autocomplete="new-password"
                                                value={editProData.description}
                                                required
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6} />
                                        <h6>Photos</h6>
                                        <Col md={12} className="pr-1">
                                            <div className="uploader-outer">
                                                <label htmlFor="pro-images-uploader" className="uploader">
                                                    <div className="inside">
                                                        <Plus />
                                                        <h4>Upload</h4>
                                                    </div>
                                                </label>
                                                <Input autocomplete="off"type="file" onChange={uploadImage} id="pro-images-uploader" />
                                                <Row>
                                                    {editProData.work_images &&
                                                        editProData.work_images.map((image, i) => (
                                                            <Col key={i} md={2}>
                                                                <div className="work_images_preview">
                                                                    <img
                                                                        src={ASSETS_URL + image}
                                                                        className="w-100 mb-2"
                                                                        style={{ height: 90, objectFit: 'cover' }}
                                                                        alt="service"
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeWorkImage(i)}>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24">
                                                                            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={8}>
                                    <ServicesList
                                        id={editProData.id}
                                        servicesList={services}
                                        services={editProData.services}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle} color="secondary" className="ml-1">
                        Cancel
                    </Button>
                    <Button color="primary" type="submit">
                        Edit Professional
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default EditProModal;
