import axios from '../../axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import PageTitle from '../PageTitle';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Chat from './chats';
import Select from 'react-select';

const Order = () => {
    const [selectePro, setSelectedPro] = useState();
    const [appliedPro, setAppliedPro] = useState();
    const [ProId, setProId] = useState()
    const [serviceTitle, setServiceTitle] = useState()
    const [serviceId, setServiceId] = useState()
    const [user, setUser] = useState()
    const [info, setInfo] = useState()
    const [answers, setAnswers] = useState([])
    const [appliedPros, setAppliedPros] = useState([])
    const [appliedId, setAppliedId] = useState(null)
    const [chats, setChatArray] = useState()
    const [offer, setOffer] = useState()
    const[orderdate,setDate] = useState()
    const lang = useSelector((state) => state.Layout.lang);
    const { id } = useParams();
    let arr = []
    let proId;
    let orderId;
    let proOrderarr = [];
    let serviceArr = []
    useEffect(() => {
        const fetchOrders = async () => {


            await axios.get(`/api/v1/admin/order/${id}`).then(res => {
                proId = res.data.order.proId
                serviceArr = res.data.services
                setProId(res.data.order.proId)
                orderId = res.data.order.id
                setServiceId(res.data.order.serviceId)
                setDate(res.data.order.createdAt)
                setUser(res.data.order.user)
                setInfo(res.data.order.info)
                setServiceTitle(serviceArr.filter(function (e) { return e.id === parseInt(res.data.order.serviceId); }))
                var Aarr = []
                for (var i = 0; i < Object.keys(res.data.order.answers).length; i++) {
                    Aarr.push(JSON.parse(res.data.order.answers[i]))
                }
                setAnswers(Aarr)
            });

            await axios.get(`/api/v2/admin/pro`).then(res => {

                arr = res.data
            })
            await axios.get('/api/v1/admin/order/orderPro').then(res => {

                proOrderarr = res.data;
            });

            axios.get('/api/v2/admin/pro').then(res => {
                var proArr = []
                proOrderarr.filter(function (e) { return e.orderId === parseInt(orderId); }).forEach(element => {
                    proArr.push(...appliedPros, res.data.filter(function (e) {
                        return e.id === element.proId
                    }
                    )
                    )
                    let newArr = []
                    proArr.forEach((a) => {
                        a.forEach((w) => {
                            newArr.push({
                                value: w.id,
                                label: w.name
                            })
                        })
                    })
                    setAppliedPros(newArr)
                });
            })

            setSelectedPro(arr.filter(function (e) { return e.id === parseInt(proId); }).map(({ name }) => name)[0])
            setAppliedPro(proOrderarr.filter(function (e) { return e.orderId === parseInt(orderId); }).length)
        }

        fetchOrders();
    }

        , [])
    function formatDate(date, format, utc) {
        var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        function ii(i, len) {
            var s = i + "";
            len = len || 2;
            while (s.length < len) s = "0" + s;
            return s;
        }

        var y = utc ? date.getUTCFullYear() : date.getFullYear();
        format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
        format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
        format = format.replace(/(^|[^\\])y/g, "$1" + y);

        var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
        format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])M/g, "$1" + M);

        var d = utc ? date.getUTCDate() : date.getDate();
        format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])d/g, "$1" + d);

        var H = utc ? date.getUTCHours() : date.getHours();
        format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
        format = format.replace(/(^|[^\\])H/g, "$1" + H);

        var h = H > 12 ? H - 12 : H === 0 ? 12 : H;
        format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])h/g, "$1" + h);

        var m = utc ? date.getUTCMinutes() : date.getMinutes();
        format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
        format = format.replace(/(^|[^\\])m/g, "$1" + m);

        var s = utc ? date.getUTCSeconds() : date.getSeconds();
        format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
        format = format.replace(/(^|[^\\])s/g, "$1" + s);

        var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
        format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])f/g, "$1" + f);

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
        format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

        var t = T.toLowerCase();
        format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
        format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

        var tz = -date.getTimezoneOffset();
        var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
        if (!utc) {
            tz = Math.abs(tz);
            var tzHrs = Math.floor(tz / 60);
            var tzMin = tz % 60;
            K += ii(tzHrs) + ":" + ii(tzMin);
        }
        format = format.replace(/(^|[^\\])K/g, "$1" + K);

        var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
    };
    const AppliedChat = (e) => {


        axios.get(`/api/v1/message/msgpro/${e.value}`).then(res => {
            const msgId = res.data.filter(function (e) { return e.userId === parseInt(user.id); }).map(({ id }) => id)[0]
            setAppliedId(msgId ? true : false)
            axios.get(`/api/v1/message/msg/${msgId}`).then(res => {
                console.log(res.data)
                setOffer(res.data)
                let chatArr = []
                res.data && res.data.messages.length > 0 && res.data.messages.forEach((w) => {

                    chatArr.push({
                        pro: w.proId ? true : false,
                        id: w.id,
                        userName: w.proId ? res.data.pro.name + "" + "(Pro)" : res.data.user.firstName + "" + "(Client)",
                        text: w.text,
                        date: formatDate(new Date(w.createdAt), "dddd h:mmtt d MMM yyyy"),
                        attachment: w.attachments.length > 0 ? w.attachments : [],
                        userPic: w.proId ? res.data.pro.profile_image : res.data.user.imageUrl
                    })
                })
                setChatArray(chatArr)
            }).catch(err => {
                setAppliedId(false)
            })
        })


    }

    return (
        <div>
            <Row className="page-title">
                <Col className="col-12">
                    <PageTitle breadCrumbItems={[{ label: 'Orders', path: '/orders', active: true }]} title={'Order Details'} />
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <h4>Order Details</h4>

                    <Row>
                        <Col md={4} className="mb-4 mt-4">
                            <Label for="service">Order Service</Label>
                            <Link to={`/services/${serviceId && serviceId}`} >
                                <Input
                                    id="service"
                                    style={{ cursor: "pointer", color: "blue" }}
                                    name="service"
                                    placeholder="Order Service"
                                    value={lang === 'Russian' ? serviceTitle && serviceTitle.map(({ titleRU }) => titleRU) : lang === 'Uzbek' ? serviceTitle && serviceTitle.map(({ titleUZ }) => titleUZ) : serviceTitle && serviceTitle.map(({ titleEN }) => titleEN)}
                                    disabled
                                />
                            </Link>
                        </Col>
                        <Col md={4} className="mb-4 mt-4">
                            <Label for="service">Order Date {"&"} Time</Label>
                      
                                <Input
                                    id="date"
                                    placeholder="Order Date"
                                    value={orderdate&&formatDate(new Date(orderdate), "dddd h:mmtt d MMM yyyy")}
                                    disabled
                                />
                            
                        </Col>

                        <Col md={4} className="mb-4 mt-4">
                            <Label for="clientn">Client Name</Label>
                            <Input autocomplete="off" type="text" id="clientn" name="clientn" value={user && user.lastName ? user.firstName + " " + user.lastName : user && user.firstName} disabled />
                        </Col>

                        <Col md={4} className="mb-4 mt-4">
                            <Label for="clientn">Client Email</Label>
                            <Input autocomplete="off" type="text" id="clientn" name="clientn" value={user && user.email} disabled />
                        </Col>

                        {user && user.phone && user.phone!==null&&<>
                            <Col md={4} className="mb-4 mt-4">
                                <Label for="clientn">Client Phone Number</Label>
                                <Input autocomplete="off" type="text" id="clientn" name="clientn" value={user && user.phone} disabled />
                            </Col>
                        </>
                        }
                        <Col md={4} className="mb-4 mt-4">
                            <Label for="pros">Number of Applied Professionals</Label>
                            <Input autocomplete="off" style={{ width: 60 }} type="number" id="pros" name="pros" value={appliedPro && appliedPro} disabled />
                        </Col>
                        <Col md={4} className="mb-4 mt-4">
                            <Label for="pros"> Applied Professionals</Label>
                            {appliedPros && appliedPros.length > 0 && appliedPros.map((e) => (
                                <Link to={`/pros/${e.value}`} >
                                    <Input autocomplete="off" style={{ cursor: "pointer", color: "blue", marginTop: "8px" }} type="text" id="pros" name="pros" value={e.label} disabled />
                                </Link>
                            ))}

                        </Col>

                        <Col md={4} className="mb-4 mt-4">
                            <Label for="pros">Selected Professional  By Client</Label>
                            <Link to={`/pros/${ProId && ProId}`} >
                                <Input autocomplete="off" style={{ cursor: "pointer", color: "purple" }} type="text" id="pros" name="pros" value={selectePro && selectePro} disabled />
                            </Link>

                        </Col>
                    </Row>
                    <h4>Answers</h4>
                    <Row className="px-2">
                        <Col md={4}>
                            <h5>Message Info.</h5>
                            <ul className="p-0" style={{ listStyle: 'none' }}>
                                <li>{info && info}</li>
                            </ul>
                        </Col>
                        {answers && answers.length > 0 && answers.map((answer) => (

                            <Col md={4}>
                                <h5>{lang === 'Russian' ? answer.key.ru : lang === 'Uzbek' ? answer.key.uz : answer.key.eng}</h5>
                                <ul className="p-0" style={{ listStyle: 'none' }}>
                                    <li>{answer.values.length ? answer.values.map((value) => (
                                        <span>{lang === 'Russian' ? value.ru : lang === 'Uzbek' ? value.uz : value.eng}</span>
                                    )) : <span>{lang === 'Russian' ? answer.values.ru : lang === 'Uzbek' ? answer.values.uz : answer.values.eng}</span>}</li>
                                </ul>
                            </Col>

                        ))}



                    </Row>
                    <Row style={{ display: "block" }}>

                        <h4 style={{ marginBottom: "6px" }} >Chat Messages {"&"} Offer Details </h4>
                        <Label>Select Applied Pro</Label>
                        <Select
                            onChange={(e) => {
                                AppliedChat(e)


                            }}
                            placeholder="Select Applied Pro"
                            className="react-select"
                            classNamePrefix="react-select"
                            options={appliedPros}></Select>
                        <Col xl={4}>
                            {appliedId === null ? <h3>Please Select Applied Pros!</h3> : appliedId === false ? <h3>No Chats Available Yet!</h3> :
                                <>
                                    <Row>
                                        {offer &&
                                            <>
                                                <Label style={{ marginTop: "1rem" }} for="offer">Offer Viewed</Label>
                                                <Input
                                                    value={offer && offer.offer_viewed && offer.offer_viewed ? formatDate(new Date(offer.offer_viewed), "dddd h:mmtt d MMM yyyy") : "Offer Not Viewed By Client Yet!"}
                                                    disabled
                                                />
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        {offer && offer.offer_price && offer.offer_price &&
                                            <>
                                                <Label style={{ marginTop: "1rem" }} for="offer">Offer Price</Label>
                                                <Input
                                                    value={offer.offer_price + "" + "$"}
                                                    disabled
                                                />
                                            </>
                                        }
                                    </Row>

                                    <Chat chats={chats} />
                                </>}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default Order;
