import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './assets/scss/theme.scss';
import axios from './axios';
import Login from './pages/auth/Login';
import { NOTIFICATIONS_SUCCESS } from './redux/appMenu/constants';
import { LOGIN_USER_SUCCESS } from './redux/auth/constants';
import Routes from './routes/Routes';

const App = () => {
    const dispatch = useDispatch();

    // const auth = useSelector((state) => state.Auth.user);

    useEffect(() => {
        const fetchUser = async () => {
            const { data } = await axios.get('/api/v1/auth/login/admin');

            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: data,
            });

            const notifications = await axios.get('/api/v1/admin/notifications');

            if (notifications.data) {
                dispatch({
                    type: NOTIFICATIONS_SUCCESS,
                    payload: notifications.data,
                });
            }
        };

        fetchUser();
    }, [dispatch]);

    return JSON.parse(localStorage.getItem("isAdmin")) ? <Routes /> : <Login />;
    // <Routes></Routes>
};

export default App;
