import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import notifications from './appMenu/notifications';
import { editQuestionModalReducer, editQuestionModalReducerKey } from '../components/EditQuestionModal';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    notifications,
    [editQuestionModalReducerKey]: editQuestionModalReducer
});
