export const SET_CLIENT_CHOICE_TYPE = 'SET_CLIENT_CHOICE_TYPE';
export const SET_PRO_CHOICE_TYPE = 'SET_PRO_CHOICE_TYPE';

export const SET_MODAL_STATE_QUESTION = 'SET_MODAL_STATE_QUESTION';

export const SET_CHOICES = 'SET_CHOICES';
export const INIT_CHOICES = 'INIT_CHOICES';
export const REORDER_CHOICES = 'REORDER_CHOICES';
export const ADD_CHOICE = 'ADD_CHOICE';
export const ADD_PRO_CHOICE = 'ADD_PRO_CHOICE';
export const REMOVE_CHOICE = 'REMOVE_CHOICE';
export const REMOVE_PRO_CHOICE = 'REMOVE_PRO_CHOICE';
export const DUPLICATE_CHOICE = 'DUPLICATE_CHOICE';
export const HANDLE_CHOICE_CHANGE = 'HANDLE_CHOICE_CHANGE';

export const RESET_DELETED_CHOICES = 'RESET_DELETED_CHOICES';