import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { Lock, Mail } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import logo from '../../assets/images/logo.png';
import axios from '../../axios';
import { LOGIN_USER_SUCCESS } from '../../redux/auth/constants';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [formError, setFormError] = useState('');

    const auth = useSelector((state) => state.Auth.user);
    const dispatch = useDispatch();

    const router = useHistory();

    const handleValidSubmit = async () => {
        const body = {
            email,
            password,
            adminCookie: true,
        };

        await axios.post('/api/v1/auth/login', body);
        const { data } = await axios.get('/api/v1/auth/login/admin');

        if (data.isAdmin) {
         console.log(data)
            localStorage.setItem('isAdmin',data.isAdmin)
            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: data,
            });
        }

        // router.replace('/dashboard');
    };

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("isAdmin"))) {
window.location.href= "/dashboard"
        }
    }, [auth, router]);

    return (
        <React.Fragment>
            <div className="account-pages my-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <Card className="">
                                <CardBody className="p-0">
                                    <Row>
                                        <Col md={6} className="p-5 position-relative">
                                            <div className="mx-auto mb-5">
                                                <a href="/">
                                                    <img src={logo} alt="" height="24" />
                                                </a>
                                            </div>

                                            <h6 className="h5 mb-0 mt-4">Welcome back!</h6>
                                            <p className="text-muted mt-1 mb-4">
                                                Enter your email address and password to access admin panel.
                                            </p>

                                            {/* {formError && (
                                                <Alert color="danger" isOpen={formError ? true : false}>
                                                    <div>{formError}</div>
                                                </Alert>
                                            )} */}

                                            <AvForm onValidSubmit={handleValidSubmit} className="authentication-form">
                                                <AvGroup className="">
                                                    <Label for="email">Email</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text">
                                                                <Mail className="icon-dual" />
                                                            </span>
                                                        </InputGroupAddon>
                                                        <AvInput
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </InputGroup>

                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                </AvGroup>

                                                <AvGroup className="mb-3">
                                                    <Label for="password">Password</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text">
                                                                <Lock className="icon-dual" />
                                                            </span>
                                                        </InputGroupAddon>
                                                        <AvInput
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Enter your password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />
                                                    </InputGroup>
                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                </AvGroup>

                                                <FormGroup className="form-group mb-0 text-center">
                                                    <Button color="primary" className="btn-block">
                                                        Log In
                                                    </Button>
                                                </FormGroup>
                                            </AvForm>
                                        </Col>

                                        <Col md={6} className="d-none d-md-inline-block">
                                            <div className="auth-page-sidebar">
                                                <div className="overlay"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
