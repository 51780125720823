import { Action, ChoiceType, DeletedChoices, ModalStateQuestion, StateChoice } from './types';
import {
    ADD_CHOICE,
    ADD_PRO_CHOICE,
    DUPLICATE_CHOICE,
    HANDLE_CHOICE_CHANGE,
    INIT_CHOICES,
    REMOVE_CHOICE,
    REMOVE_PRO_CHOICE,
    REORDER_CHOICES,
    SET_CHOICES,
    SET_CLIENT_CHOICE_TYPE, SET_MODAL_STATE_QUESTION, SET_PRO_CHOICE_TYPE
} from './actionTypes';
import { AddChoice, AddProChoice, DuplicateChoice, HandleChoiceChange, RemoveChoice } from './actions';

interface InitState {
    clientChoiceType: ChoiceType,
    proChoiceType: ChoiceType,
    question: ModalStateQuestion;
    choices: StateChoice[];
    deletedChoices: DeletedChoices[];
}

const question = {
    filter: false,
    proPage: false,
    skip: false,
    clientOnly: false,
    inputBar: false,
    showImages: false,
    clientTitleEnglish: '',
    clientTitleRussian: '',
    clientTitleUzbek: '',
    proTitleEnglish: '',
    proTitleRussian: '',
    proTitleUzbek: '',
    descriptionId: null,
    descriptionEnglish: '',
    descriptionRussian: '',
    descriptionUzbek: '',
}

export const INIT_STATE: InitState = {
    clientChoiceType: 'single',
    proChoiceType: 'multiple',
    question,
    choices: [],
    deletedChoices: [],
};

const addChoice = (state: InitState, action: AddChoice): InitState => {
    const existingChoices = state.choices;
    const newChoice = action.payload;
    newChoice.order = existingChoices.length;
    return {
        ...state,
        choices: [...existingChoices, newChoice]
    };
}

const removeChoice = (state: InitState, action: RemoveChoice): InitState => {
    const index = action.payload;
    const choices = [...state.choices];
    if (choices.length > 1) {
        const deletedChoices = state.deletedChoices;
        const deletedChoice = {
            id: choices[index].idCopy,
            proId: choices[index].proId,
        }
        const updatedChoices = choices.filter((_, i) => i !== index);
        return {
            ...state,
            choices: updatedChoices,
            deletedChoices: [...deletedChoices, deletedChoice]
        };
    }
    return state;
}
const addProChoice = (state: InitState, action: AddProChoice): InitState => {
    const index = action.payload;
    const choices = [...state.choices];
    choices[index].proEnglish = '';
    choices[index].proRussian = '';
    choices[index].proUzbek = '';
    choices[index].proChoices = true;
    choices[index].newProChoices = true;
    choices[index].proSideEdited = false;

    return {
        ...state,
        choices: choices,
    };
}

const duplicateChoice = (state: InitState, action: DuplicateChoice): InitState => {
    const index = action.payload;
    const choices = [...state.choices];
    choices[index].proEnglish = choices[index].clientEnglish;
    choices[index].proRussian = choices[index].clientRussian;
    choices[index].proUzbek = choices[index].clientUzbek;
    choices[index].proChoices = true;
    choices[index].newProChoices = true;
    choices[index].proSideEdited = false;

    return {
        ...state,
        choices: choices,
    };
}

const handleChoiceChange = (state: InitState, action: HandleChoiceChange): InitState => {
    const { updateKey, index, pro, changeValue } = action.payload;
    const choices = state.choices;
    choices[index][updateKey] = changeValue;
    if (pro) {
        if(!choices[index].newProChoices){
            choices[index].proSideEdited = true;
        }
    } else {
        choices[index].edited = true;
    }
    return {
        ...state,
        choices: choices
    }
}
const removeProChoice = (state: InitState, action: RemoveChoice): InitState => {
    const choices = [...state.choices];
    const index = action.payload;

    choices[index].proChoices = false;
    choices[index].proEnglish = '';
    choices[index].proRussian = '';
    choices[index].proUzbek = '';
    choices[index].choiceProDeleted = true;
    choices[index].newProChoices = false;
    choices[index].proSideEdited = false;
    
    return {
        ...state,
        choices: choices
    }
}


export const editQuestionModalReducer = (state = INIT_STATE, action: Action): InitState => {
    switch (action.type) {
        case SET_CLIENT_CHOICE_TYPE:
            return {
                ...state,
                clientChoiceType: action.payload,
            };
        case SET_PRO_CHOICE_TYPE:
            return {
                ...state,
                proChoiceType: action.payload,
            };
        case SET_MODAL_STATE_QUESTION:
            return {
                ...state,
                question: action.payload,
            };
        case SET_CHOICES:
        case INIT_CHOICES:
        case REORDER_CHOICES:
            return {
                ...state,
                choices: action.payload,
            }
        case ADD_CHOICE: return addChoice(state, action)
        case REMOVE_CHOICE: return removeChoice(state, action)
            
        case DUPLICATE_CHOICE: return duplicateChoice(state, action)
        case ADD_PRO_CHOICE: return addProChoice(state, action)
        case HANDLE_CHOICE_CHANGE: return handleChoiceChange(state, action)
        case REMOVE_PRO_CHOICE: return removeProChoice(state, action)
        default:
            return state;
    }
};
