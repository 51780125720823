import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CheckCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import {
    Button,
    Card,
    CardBody,
    Col,

    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledAlert,
 
} from 'reactstrap';
import axios from '../axios';
import PageTitle from '../components/PageTitle';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input
            type="select"
            name="select"
            id="no-entries"
            className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>;
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);

const Table = ({ services, setActiveService }) => {
    function actionsFormatter(cell, row) {
        return (
            <Button
                onClick={() => setActiveService(row)}
                className="mr-2 p-1 d-flex align-items-center"
                size="sm"
                color="primary">
                <CheckCircle size={16} />
                <span className="ml-2">Approve Service</span>
            </Button>
        );
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'category',
            text: 'Category',
            sort: true,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            sort: true,
            formatter: actionsFormatter,
        },
    ];

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'asc',
        },
    ];

    const { SearchBar } = Search;

    return (
        <>
        {!services?<div style={{position:"absolute",left:"56%",top:"50%",transform:"translate(-50%, -50%)"}}><lottie-player src="loader.json"  background="transparent"  speed="1"  style={{width:"150px",height:"150px"}} loop  autoplay></lottie-player>
        <p style={{textAlign:"center",marginTop:"1rem",fontWeight:"bold", color:"purple"}}>Please Wait ...</p> </div>

        :
        <Card>
            <CardBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={services}
                    columns={columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {(props) => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Row className="align-items-center pl-2">
                                        <p className="m-0 mr-2">Search: </p>
                                        <SearchBar {...props.searchProps} />
                                    </Row>
                                </Col>
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={true}
                                hover
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageRenderer: sizePerPageRenderer,
                                    sizePerPageList: [
                                        { text: '10', value: 10 },
                                        { text: '25', value: 25 },
                                        { text: '50', value: 50 },
                                    ],
                                })}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
}
</>
    );
};

const Services = () => {
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [alert, setAlert] = useState(null);
    const [activeService, setActiveService] = useState(null);

    const [serviceEnglish, setServiceEnglish] = useState('');
    const [serviceRussian, setServiceRussian] = useState('');
    const [serviceUzbek, setServiceUzbek] = useState('');

    const lang = useSelector((state) => state.Layout.lang);

    useEffect(() => {
        if (activeService) {
            setServiceEnglish(activeService.name);
        }
    }, [activeService]);

    useEffect(() => {
        const fetchServices = async () => {
            const { data } = await axios.get('/api/v1/admin/service/pending');

            const servicesArr = data.map((service) => {
                return {
                    ...service,
                    category: service.category ? service.category.titleEN : '',
                };
            });

            setServices(servicesArr);

            const categories = await axios.get('/api/v1/admin/category');
            const categoriesArr = categories.data.map((category) => {
                return {
                    value: category.id,
                    label: category.titleEN,
                };
            });

            setCategories(categoriesArr);
        };

        fetchServices();
    }, [lang]);

    const handleChangeService = (e) => {
        activeService.categoryId = e.value;
        setActiveService({
            ...activeService,
        });
    };

    const handleClose = () => {
        setServiceEnglish('');
        setServiceRussian('');
        setServiceUzbek('');
        setActiveService(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!activeService.categoryId) {
            return setAlert({
                color: 'danger',
                message: 'You must select a category',
            });
        }

        await axios.post('/api/v1/admin/service/pending', {
            ...activeService,
            serviceRussian,
            serviceEnglish,
            serviceUzbek,
        });

        const newFilters = services.filter((s) => s.id !== activeService.id);
        setServices([...newFilters]);

        handleClose();
    };

    return (
        <div>
            <Row className="page-title">
                <Col className="col-12">
                    <PageTitle
                        breadCrumbItems={[{ label: 'Pending Services', path: '/pending-services', active: true }]}
                        title={'Pending Services'}
                    />
                </Col>
            </Row>
            <Table setActiveService={setActiveService} services={services} />
            <Modal isOpen={!!activeService}>
                <ModalHeader>Adding Requested Service</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    {activeService && (
                        <ModalBody>
                            {alert && <UncontrolledAlert color={alert.color}>{alert.message}</UncontrolledAlert>}
                            <div className="mb-4">
                                <h6>Service</h6>

                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    onChange={handleChangeService}
                                    defaultValue={{
                                        value: activeService.categoryId,
                                        label: activeService.category,
                                    }}
                                    options={categories}></Select>
                            </div>
                            <div className="mb-4">
                                <Label for="name">Title English</Label>
                                <Input
                                    name="name"
                                    id="nameEn"
                                    placeholder="English Name"
                                    value={serviceEnglish}
                                    onChange={(e) => setServiceEnglish(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <Label for="name">Title Russian</Label>
                                <Input
                                    name="name"
                                    id="nameRu"
                                    placeholder="Russian Name"
                                    value={serviceRussian}
                                    onChange={(e) => setServiceRussian(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <Label for="name">Title Uzbek</Label>
                                <Input
                                    name="name"
                                    id="nameUz"
                                    placeholder="Uzbek Name"
                                    value={serviceUzbek}
                                    onChange={(e) => setServiceUzbek(e.target.value)}
                                    required
                                />
                            </div>
                        </ModalBody>
                    )}
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button type="button" onClick={handleClose} className="mr-2">
                                Close
                            </Button>
                            <Button color="primary">Add Service</Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Services;
